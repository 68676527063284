import "./Plan.scss"
import { useState, useEffect, useContext } from "react"
import Modal from "../../../components/Modal/Modal"
import Toast from "../../../components/Toast";
import DestinationApi from "../../../api/destination"

import Context from "../../../context"

export default function Plan() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)

  const [user, setUser] = useState({})
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [plan, setPlan] = useState({});
  const [stock, setStock] = useState([]);
  const [bread, setBread] = useState([]);
  const [level, setLevel] = useState(0);


  // 获取成就分类
  function getPlanList() {
    DestinationApi.getTreeMarkUser("2", user.id || JSON.parse(localStorage.getItem('user')).id, user.teamId || JSON.parse(localStorage.getItem('user')).teamId).then(res => {
      res.data.forEach(v => v.show = false)
      setList([...res.data])
    })
  }
  // 展开或关闭成就分类
  function toggleCollapse(item) {
    setList(list.map(v => {
      if (v.id === item.id) {
        v.show = !item.show
        setStock([v])
        setLevel(0)
        setBread(['*'])
      } else {
        v.show = false
      }
      return v
    }))
  }
  // 点击子分类
  function childClick(child) {
    setStock([...stock, child])
    setLevel(level + 1)
    setBread([...bread, child.name])
    addCallback(()=>{
      setStock(stock.slice(0, level + 1))
      setLevel(level)
      setBread(bread.slice(0, level + 1))
      removeCallback()
    },null)
  }
  // 点击面包屑
  function breadClick(idx) {
    for(let i=level;i>idx;i--){
      removeCallback()
    }
    setStock(stock.slice(0, idx + 1))
    setLevel(idx)
    setBread(bread.slice(0, idx + 1))
  }
  // 点击成就
  function planClick(plan) {
    setPlan(plan)
    setShowModal(true)
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))
    getPlanList()
  }, [])

  useEffect(() => {
    if (showModal) {
      // 添加tabbar回调
      addCallback(() => { setShowModal(false); removeCallback(); }, () => {
        // 提交数据
        DestinationApi.change({
          userId: user.id,
          id: plan.id
        }).then(res => {
          getPlanList()
          Toast.success("修改成功", 3000)
          setShowModal(false);
          removeCallback();
        })
      })
    }
  }, [showModal])

  return <div className="page plan">
    <div className="plan-category-list">
      {
        list.map(item => (
          <div key={item.id} className={`item ${item.show ? 'active' : ''}`}>
            <div className="title" onClick={() => toggleCollapse(item)}>
              <div className="icon">
                <img src={require(`../../../assets${item.icon}`)} />
              </div>
              <div className="text">{item.name}</div>
            </div>
            {
              item.show && <div className="content">
                <div className="breads">
                  {
                    bread.map((b, idx) => <div key={idx} onClick={() => { breadClick(idx) }} className="bread">/ {b}</div>)
                  }
                </div>
                <div className="children">
                  {
                    stock[level].children.map(child => <div className="child" onClick={() => childClick(child)} key={child.id}>
                      {child.name}
                    </div>)
                  }
                </div>
                <div className="plan">
                  {
                    stock[level].destination.map(target => <div key={target.id} className={`plan-item ${target.users.length ? 'done' : ''}`} onClick={() => planClick(target)}>
                      <div className="plan-info">
                        <div className="plan-icon">
                          <img src={require("../../../assets/cute/icons8-prize-100.png")} alt="" />
                        </div>
                        <div className="plan-name">
                          {target.name}
                        </div>
                      </div>
                      <div className="plan-state">
                        {target.users.length ?
                          <img src={require('../../../assets/cute/icons8-check-circle-128.png')} /> :
                          <img src={require('../../../assets/cute/icons8-check-circle.gif')} />}
                      </div>
                    </div>)
                  }
                </div>
              </div>
            }
          </div>
        ))
      }
    </div>
    <Modal show={showModal} title={plan.name} tips={{ ok: plan.users && plan.users.length ? '关闭' : '切换', cancel: '返回' }}>
      <div className="modal-info">
        <div className="details">
          {plan.details || "暂无说明"}
        </div>
        {
          plan.users && plan.users.length ? <div className="done">
            <img src={require('../../../assets/cute/icons8-check-circle.gif')} />
          </div> : ''
        }
      </div>

    </Modal>
  </div>
}