import axios from "./request"
const check = {
  getNum({ userId, teamId}) {
    return axios.get("/check/num", { params: { userId, teamId} })
  },
  getList({ teamId, type, status, sort }) {
    return axios.get("/check/list", { params: { teamId, type, status, sort } })
  },
  checkAchieve({id, status}){
    return axios.get("/check/achieve", { params: { id,status } })
  },
  checkWithdraw({id, status}){
    return axios.get("/check/withdraw", { params: { id,status } })
  },
  checkLoan({id, status}){
    return axios.get("/check/loan", { params: { id, status } })
  },
  checkProxy({id, status, checker}){
    return axios.get("/check/proxy", { params: { id, status, checker } })
  },
}
export default check