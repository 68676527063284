import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./styles/index.scss"
import bankUtils from "./utils/bank"
React.$moneyFormat = bankUtils.moneyFormat

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// 如果您想开始测量应用程序的性能，请传递一个函数
// 记录结果（例如：reportWebVitals(console.log)）
// 或发送到分析端点。 了解更多：https://bit.ly/CRA-vitals
reportWebVitals(console.log);
