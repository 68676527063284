const bankUtils = {
  amountUnit: {
    '100': '百',
    '1000': '千',
    '10000': '万',
    '100000': '十万',
    '1000000': '百万',
    '10000000': '千万',
    '100000000': '亿',
    '1000000000': '十亿',
    '10000000000': '百亿',
  },
  getUnit: (amount) => {
    if (amount <= 100) return ""
    let unit = 10
    for (let flag = 10; flag < 1000000000000001; flag *= 10) {
      if (amount < flag) {
        unit = flag / 10
        break
      }
    }
    return '(最高位：' + bankUtils.amountUnit[unit] + ')'
  },
  moneyFormat(amountStr, { decimalPlaces = 2, digit = 4, mark = "," } = {}) {
    let [integer, decimal] = String(amountStr).split("."), newIntegerArr = [];
    // 小数保留decimalPlaces位
    decimal = Number("0." + (decimal || 0) ).toFixed(decimalPlaces).split(".")[1]
    // 将整数间隔digit位添加mark
    integer.split("").reverse().forEach((v, i) => {newIntegerArr.push(v);(i % digit === digit - 1) && newIntegerArr.push(mark)})
    newIntegerArr = newIntegerArr.reverse();
    //去除第一位mark
    (newIntegerArr[0] === mark) && newIntegerArr.shift()
    return newIntegerArr.join("") + "." + decimal
  }
}

export default bankUtils