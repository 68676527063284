import { createContext, useState } from "react"

const Context = createContext()
window.backFnList = []
window.okFnList = []

function MyContext({ children }) {

  function addCallback(back, ok) {
    back && (typeof back === 'function') && (window.backFnList.unshift(back))
    ok && (typeof ok === 'function') && (window.okFnList.unshift(ok))
  }
  function doCallback(key) {
    if(key==='back'){
      const backFn = window.backFnList[0];
      (typeof backFn==="function") && backFn();
    }
    if(key==='ok'){
      const okFn = window.okFnList[0];
      (typeof okFn==="function") && okFn();
    }
  }
  function removeCallback(key) {
    if(key==='back'){
      window.backFnList.shift()
    }
    if(key==='ok'){
      window.okFnList.shift()
    }
    if(!key){
      window.backFnList.shift()
      window.okFnList.shift()
    }
  }
  function clearCallback() {
    window.backFnList = []
    window.okFnList = []
  }

  return <Context.Provider value={{ addCallback, doCallback, removeCallback, clearCallback }}>
    {children}
  </Context.Provider>
}

Context.MyContext = MyContext

export default Context