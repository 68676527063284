import { useEffect, useState } from "react"
import ReactDOM from 'react-dom'
import Picker from "./Picker"

import style from "./DatePicker.module.scss"
export default function DatePicker(props) {
  let picker 
  const [div, setDiv] = useState({})
  const [year, setYear] = useState()
  const [month, setMonth] = useState()
  const [day, setDay] = useState()

  const [showPicker, setShowPicker] = useState(false)

  useEffect(()=>{
    const div = document.createElement('div')
    div.id=`date-picker-${String(new Date().getTime()).slice(-4)}`
    document.body.appendChild(div)
    setDiv(div)
  },[])
  // 创建
  function createPicker() {
    ReactDOM.render(<Picker key={"Picker" + new Date().getTime()} 
      year={year}
      month={month}
      day={day}
      setYear={setYear}
      setMonth={setMonth}
      setDay={setDay}
      showPicker={showPicker}
      cancel={cancel}
      confirm={confirm}
    />, div)
    
    return {
      destroy() {
        ReactDOM.unmountComponentAtNode(div)
        document.getElementById(div.id).innerHTML=""
      }
    }
  }

  function openPicker() {
    if (props.date) {
      setYear(new Date(props.date).getFullYear())
      setMonth(new Date(props.date).getMonth() + 1)
      setDay(new Date(props.date).getDate())
    } else {
      setYear(new Date().getFullYear())
      setMonth(new Date().getMonth() + 1)
      setDay(new Date().getDate())
    }
    setShowPicker(true)
  }
  
  useEffect(()=>{
    if (props.date) {
      setYear(new Date(props.date).getFullYear())
      setMonth(new Date(props.date).getMonth() + 1)
      setDay(new Date(props.date).getDate())
    } else {
      setYear(new Date().getFullYear())
      setMonth(new Date().getMonth() + 1)
      setDay(new Date().getDate())
    }
  },[props.date])

  useEffect(() => {
    if(showPicker){
      props.onPickerChange && typeof props.onPickerChange === 'function' && props.onPickerChange(true)
      typeof picker?.destroy === 'function' && picker.destroy()
      picker  = createPicker()
    }else{
      props.onPickerChange && typeof props.onPickerChange === 'function' && props.onPickerChange(false)
      typeof picker?.destroy === 'function' && picker.destroy()
    }
  }, [showPicker, props,
    year,
    month,
    day,
    setYear,
    setMonth,
    setDay,
    showPicker,
    cancel,
    confirm,])



  function cancel() {
    setYear(undefined)
    setMonth(undefined)
    setDay(undefined)
    setShowPicker(false)
    typeof picker?.destroy === 'function' && picker.destroy()
    props?.onChange && typeof props.onChange === 'function' && props.onChange(undefined)
  }

  function confirm() {
    setShowPicker(false)
    let obj = {
      year, month, day,
      string: `${year}-${month}-${day}`,
      date: new Date(`${year}-${month}-${day}`),
    }
    typeof picker?.destroy === 'function' && picker.destroy()
    props?.onChange && typeof props.onChange === 'function' && props.onChange(obj)
  }

  return <div className={style.DatePicker}>
    <div className={`date ${props.styleType}  ${props.styleType==='line' && props.align || 'vertical'}`} onClick={openPicker}>
      {props.styleType ==='line'?<div className="label">{props.label}</div>:''}
      <div className="date-string"><span>{year}</span> - <span>{month}</span> - <span>{day}</span></div>
    </div>
  </div>
}