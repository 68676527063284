import axios from "./request"
const bank = {
  getBill({ userId, billId, type, start, end, sort, page = 1, size = 50 }) {
    return axios.get(`/bank/getBill`, { params: { userId, billId, type, start, end, sort, page, size } })
  },
  getCurrent(id) {
    return axios.get(`/bank/getCurrent/${id}`)
  },
  getCurrentList({ id, start, end, sort, page = 1, size = 50 }) {
    return axios.get(`/bank/getCurrentList`, { params: { id, start, end, sort, page, size } })
  },
  saveCurrent({ id, amount, remark }) {
    return axios.post(`/bank/saveCurrent`, { id, amount, remark })
  },
  outCurrent({ id, amount, remark }) {
    return axios.post(`/bank/outCurrent`, { id, amount, remark })
  },
  withdraw({ teamId, userId, amount, type, remark }) {
    return axios.post(`/bank/withdraw`, { teamId, userId, amount, type, remark })
  },
  getFixedList(userId){
    return axios.get(`/bank/getFixedList`, { params: { userId } })
  },
  getFixedTotal(userId){
    return axios.get(`/bank/getFixedTotal`, { params: { userId } })
  },
  saveFixed({userId, amount, endTime, endType, remark}){
    return axios.post(`/bank/saveFixed`, { userId, amount, endTime, endType, remark })
  },
  outFixed({id, userId}){
    return axios.post(`/bank/outFixed`, { id, userId })
  },
  getLoanAmount(userId){
    return axios.get(`/bank/loanAmount`, { params: { userId } })
  },
  getLoanRecords(userId){
    return axios.get(`/bank/loanRecords`, { params: { userId } })
  },
  createLoan({userId, teamId, amount, period, remark}){
    return axios.post(`/bank/createLoan`, { userId, teamId, amount, period, remark })
  },
  repayLoan({id, userId}){
    return axios.post(`/bank/repayLoan`, { id, userId })
  },
  getProxyList(teamId){
    return axios.get(`/bank/getProxy`, { params: { teamId } })
  },
  createProxy({userId, teamId, amount, operator, remark}){
    return axios.post(`/bank/createProxy`, { userId, teamId, amount, operator, remark })
  },
}
export default bank