import "./DestinationCategory.scss"
import { useEffect, useState, useContext } from "react"
import TitleBar from "../../../components/TitleBar/TitleBar"
import LineInput from "../../../components/LineInput/LineInput"
import Cascader from "../../../components/Cascader/Cascader"
import Toast from "../../../components/Toast"
import CategoryApi from "../../../api/category"
import Modal from "../../../components/Modal/Modal"

import Context from "../../../context"

export default function DestinationCategory() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)
  const [user, setUser] = useState({})
  const [expandAdd, setExpandAdd] = useState(false)
  const [cate, setCate] = useState({ pid: "", id: "", name: '', sort: 1, remark: '' })
  const [categoryList, setCategoryList] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDelModal, setShowDelModal] = useState(false)
  const [delItem, setDelItem] = useState({})

  async function getCategoryList() {
    const res = await CategoryApi.getTree('2', user.teamId || JSON.parse(localStorage.getItem("user")).teamId)
    setCategoryList(res.data)
  }
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")))
    getCategoryList()
    return () => {
      removeCallback('ok')
    }
  }, [])


  function showAdd() {
    setCate({ pid: "", id: "", name: '', sort: 1, remark: '' })
    setExpandAdd(!expandAdd)
  }
  function cascaderChange(item) {
    setCate({ ...cate, pid: item.id })
  }
  function cateChange(v, key) {
    setCate({
      ...cate,
      [key]: v
    })
  }
  useEffect(() => {
    removeCallback('ok')
    addCallback(null, () => {
      if (!cate.pid || !cate.name) {
        Toast.error("请选择父级，且名称不能为空")
        return
      }
      CategoryApi.addCategory({ ...cate, teamId:user.teamId }).then(() => {
        Toast.success("添加成功")
        setCate({ pid: "", id: "", name: '', sort: Number(cate.sort)+1, remark: '' })
        getCategoryList()
      })
    })
  }, [cate])

  function editItem(item) {
    setCate({ ...item })
    setShowEditModal(true)
    setExpandAdd(false)
  }

  useEffect(() => {
    if (showEditModal) {
      addCallback(() => { setShowEditModal(false); removeCallback() }, () => {
        CategoryApi.editCategory({ ...cate, teamId:user.teamId }).then(() => {
          Toast.success("修改成功")
          getCategoryList()
          setShowEditModal(false);
          removeCallback();
        })
      })
    }
  }, [showEditModal, cate])

  function deleteItem(item) {
    setDelItem(item)
    setShowDelModal(true)
    setExpandAdd(false)
  }


  useEffect(() => {
    if (showDelModal) {
      addCallback(() => { setShowDelModal(false); removeCallback() }, () => {
        CategoryApi.delCategory(delItem.id, user.teamId).then(() => {
          Toast.success("删除成功")
          getCategoryList()
          setShowDelModal(false);
          removeCallback();
        })
      })
    }
  }, [showDelModal, delItem])


  function isBelongTeamOrDefault(item){
    return item.teamId === user.teamId
  }

  function showList(list, lev) {
    if (list.length) {
      return list.map(item => {
        return <div className={`l-card l-${lev}`} key={item.id}>
          <div className="title">
            <div className="name">
              {item.pid === 1 ? <img alt="" className="a-logo" src={require(`../../../assets${item.icon}`)} /> : ''}
              {item.name}
            </div>
            <div className="btns">
              {isBelongTeamOrDefault(item)?<span className="edit" onClick={() => editItem(item)}><img alt="" src={require("../../../assets/cute/icons8-edit.gif")} /></span>:null}
              {isBelongTeamOrDefault(item)?<span className="del" onClick={() => deleteItem(item)}><img alt="" src={require("../../../assets/cute/icons8-trash-can.gif")} /></span>:null}
            </div>
          </div>
          {(item.children && item.children.length) ? showList(item.children, lev + 1) : null}
        </div>
      })
    } else {
      return null
    }
  }


  return <div className="page destination-category">
    <div className={`card add-cate ${expandAdd ? 'expand' : ''}`}>
      <div className="add-title" onClick={showAdd}><img alt="" src={require("../../../assets/cute/icons8-plus.gif")}></img></div>
      <div className="form">
        <Cascader label="父级" option={categoryList} onChange={cascaderChange} align="horizontal"></Cascader>
        <LineInput label="名称" value={cate.name} onChange={(e) => { cateChange(e.target.value, 'name') }} align="horizontal"></LineInput>
        <LineInput label="备注" value={cate.remark} onChange={(e) => { cateChange(e.target.value, 'remark') }} align="horizontal"></LineInput>
        <LineInput label="排序" value={cate.sort} onChange={(e) => { cateChange(e.target.value, 'sort') }} type="number" align="horizontal"></LineInput>
      </div>
    </div>
    <TitleBar title="目的地分类"></TitleBar>
    <div className="list">
      {showList(categoryList, 1)}
    </div>

    <Modal show={showEditModal} title="编辑分类" tips={{ ok: '提交', cancel: '返回' }}>
      <Cascader label="父级" option={categoryList} onChange={cascaderChange} align="horizontal" placeholder="选择则改变"></Cascader>
      <LineInput label="名称" value={cate.name} onChange={(e) => { cateChange(e.target.value, 'name') }} align="horizontal"></LineInput>
      <LineInput label="备注" value={cate.remark} onChange={(e) => { cateChange(e.target.value, 'remark') }} align="horizontal"></LineInput>
      <LineInput label="排序" value={cate.sort} onChange={(e) => { cateChange(e.target.value, 'sort') }} type="number" align="horizontal"></LineInput>
    </Modal>
    <Modal show={showDelModal} title="删除分类" tips={{ ok: '删除', cancel: '返回' }}>
      <span style={{ color: "var(--error)" }}>删除 <strong>{delItem.name}</strong> 分类后，不可找回，确认删除？</span>
    </Modal>
  </div>
}