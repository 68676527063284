import "./Home.scss"
import { Link } from "react-router-dom"
import PageIcon from "../../components/PageIcon/PageIcon"
import { useEffect, useState } from "react"
import CheckApi from "../../api/check"
import TeamApi from "../../api/team"

export default function Home() {
  const [user, setUser] = useState({})
  const [checkBadge, setCheckBadge] = useState(0)
  const [teamBadge, setTeamBadge] = useState(0)

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))
    if (user?.isGuider || JSON.parse(localStorage.getItem('user'))?.isGuider) {
      CheckApi.getNum({
        userId: user.id || JSON.parse(localStorage.getItem('user')).id,
        teamId: user.teamId || JSON.parse(localStorage.getItem('user')).teamId
      }).then(res => {
        setCheckBadge(Object.values(res.data).reduce((prev, next) => prev + next))
      })
      TeamApi.getNum(user.teamId || JSON.parse(localStorage.getItem('user')).teamId).then(res => {
        setTeamBadge(res.data)
      })
    }
  }, [])

  return <div className="page home">
    <Link to='/user'>
      <div className="card user-bar">
        <div className="avatar">
          <img src={user?.avatar || require('../../assets/cute/icons8-google-photos-256.png')} alt="" />
          <span>{user?.nickname}</span>
        </div>
        <div className="motto">-- {user?.motto}</div>
        {teamBadge ? <div className="badge">{teamBadge}</div> : ''}
      </div>
    </Link>
    <Link to='/achievement'>
      <PageIcon src={require('../../assets/cloud/icons8-勋章-100.png')} title="成就"></PageIcon>
    </Link>
    <Link to='/punish'>
      <PageIcon src={require('../../assets/cloud/icons8-错误-100.png')} title="惩罚"></PageIcon>
    </Link>
    <Link to='/bank'>
      <PageIcon src={require('../../assets/cloud/icons8-museum-100.png')} title="银行"></PageIcon>
    </Link>
    <Link to='/travel'>
      <PageIcon src={require('../../assets/cloud/icons8-世界地图-100.png')} title="出行"></PageIcon>
    </Link>
    <Link to='/list'>
      <PageIcon src={require('../../assets/cloud/icons8-采购订单-100.png')} title="清单"></PageIcon>
    </Link>
    <Link to='/wallet'>
      <PageIcon src={require('../../assets/cloud/icons8-钱袋子-100.png')} title="钱包"></PageIcon>
    </Link>

    {
      user?.isGuider ?
        <Link to='/manage'>
          <PageIcon src={require('../../assets/cloud/icons8-支持-100.png')} title="管理中心"></PageIcon>
        </Link> : ''
    }
    {
      user?.isGuider ?
        <Link to='/check'>
          <PageIcon src={require('../../assets/cloud/icons8-信息-100.png')} title="审核中心" badge={checkBadge}></PageIcon>
        </Link> : ''
    }
  </div>
}