import "./Manage.scss"
import { Link } from "react-router-dom"
import PageIcon from "../../components/PageIcon/PageIcon"

export default function Manage() {
  return <div className="page manage">
    <div className="page-title">管理中心</div>
    <Link to='/manage/achievecategory'>
      <PageIcon src={require('../../assets/cloud/icons8-1文凭-100.png')} title="成就分类"></PageIcon>
    </Link>
    <Link to='/manage/achieve'>
      <PageIcon src={require('../../assets/cloud/icons8-勋章-100.png')} title="成就管理"></PageIcon>
    </Link>
    <Link to='/manage/depot'>
      <PageIcon src={require('../../assets/cloud/icons8-店-100.png')} title="仓库管理"></PageIcon>
    </Link>
    <Link to='/manage/destination-category'>
      <PageIcon src={require('../../assets/cloud/icons8-填充的星-100.png')} title="目的地分类"></PageIcon>
    </Link>
    <Link to='/manage/destination'>
      <PageIcon src={require('../../assets/cloud/icons8-纸飞机-100.png')} title="目的地管理"></PageIcon>
    </Link>
  </div>
}