import style from "./Toast.module.scss"
import React from "react";
class Toast extends React.Component {
  constructor(props) {
    super(props);
    this.state = { notices: [] }
  }

  getNoticeKey() {
    return `notice-${new Date().getTime()}`
  }

  addNotice(notice) {
    const { notices } = this.state
    notice.key = this.getNoticeKey()

    notices.push(notice);

    this.setState({ notices })
    if (notice.duration > 0) {
      setTimeout(() => {
        this.removeNotice(notice.key)
      }, notice.duration)
    }
    return () => { this.removeNotice(notice.key) }
  }

  removeNotice(key) {
    const { notices } = this.state
    this.setState({
      notices: notices.filter((notice) => {
        if (notice.key === key) {
          if (notice.onClose) notice.onClose()
          return false
        }
        return true
      })
    })
  }

  render() {
    // 使用 `ref` 的回调函数将 text 输入框 DOM 节点的引用存储到 React
    return (
      <div className={style.toasts} onClick={this.addNotice}>
        {this.state.notices.map((notice, index) => {
          return <div className="toast-box" key={notice.key + index}>
            <div className={`toast ${notice.type}`}>{notice.content}</div>
          </div>
        })}
      </div>
    );
  }
}
export default Toast