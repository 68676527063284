import axios from "./request"

const achievement = {
  getTree(id, teamId) {
    return axios.get("/achievement/tree", { params: { id, teamId } })
  },
  getTreeMarkUser(id, userId, teamId) {
    return axios.get("/achievement/treeMarkUser", { params: { id, userId, teamId } })
  },
  add({ name, teamId, remark, color, categoryId, morality, courage, perseverance, knowledge, wisdom, physique, communicate, creativity, implement, coin }) {
    return axios.post("/achievement/add", { name, teamId, remark, color, categoryId, morality, courage, perseverance, knowledge, wisdom, physique, communicate, creativity, implement, coin })
  },
  edit({ id, teamId, name, remark, color, categoryId, morality, courage, perseverance, knowledge, wisdom, physique, communicate, creativity, implement, coin }) {
    return axios.post("/achievement/edit", { id, teamId, name, remark, color, categoryId, morality, courage, perseverance, knowledge, wisdom, physique, communicate, creativity, implement, coin })
  },
  del(id, teamId) {
    return axios.delete("/achievement/delete", { params: { id, teamId } })
  },
  addCheck({ teamId, userId, achievementId }) {
    return axios.post("/achievement/addCheck", { teamId, userId, achievementId })
  },
}
export default achievement