import "./Random.scss"
import { useState, useEffect, useContext } from "react"
// import Map from "../../../components/Map/Map"
// import Modal from "../../../components/Modal/Modal"
import Toast from "../../../components/Toast";
import DestinationApi from "../../../api/destination"

import Context from "../../../context"

export default function Random() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)
  const [user, setUser] = useState({})
  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)
  const [target, setTarget] = useState([])
  const [showDetails, setShowDetails] = useState(false)
  const [locDetails, setLocDetails] = useState({})
  const [showLocDetails, setShowLocDetails] = useState(false)
  const [sponsor, setSponsor] = useState(0)
  const [showSponsor, setShowSponsor] = useState(false)
  const [condition, setCondition] = useState("(无)")


  function getDestinationList() {
    DestinationApi.getList({teamId: user.teamId || JSON.parse(localStorage.getItem('user')).teamId}).then(res => {
      setList(res.data)
      setTotal(res.total)
    })
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')) || {})
    getDestinationList()
  }, [])

  function getRandomTarget() {
    let i = Math.floor(Math.random() * (total))
    setTarget(list[i])
    return list[i]
  }
  function animation() {
    setShowDetails(false)
    let duration = 100
    for (let i = 1; i <= 30; i++) {
      duration = duration + 100 * Math.ceil(i / 10);
      setTimeout(() => {
        getRandomTarget()
        if (i === 30) {
          setShowDetails(true)
          locateSearch()
        }
      }, duration)
    }
  }
  function locateSearch() {
    //TODO: 地图搜索定位
  }

  function randomLoc() {
    setLocDetails({})
    setShowLocDetails(false)
    Toast.info('开发中，敬请期待！')
  }

  function getAge() {
    let birthday = {
      year: new Date(user.birthday).getFullYear(),
      month: new Date(user.birthday).getMonth() + 1,
      day: new Date(user.birthday).getDate(),
    }
    let now = {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      day: new Date().getDate(),
    }
    let age = now.year - birthday.year
    if (now.month < birthday.month ||
      (now.month === birthday.month && now.day < birthday.day)) {
      age -= 1
    }
    return age
  }
  // 随机赞助
  function randomSponsor() {
    setShowSponsor(false)
    let age = getAge()
    if (age < 6) {
      Toast.warn("年龄不达标，请6岁以后再来！")
      return
    }
    let duration = 100
    for (let i = 1; i <= 30; i++) {
      duration = duration + 100 * Math.ceil(i / 10);
      setTimeout(() => {
        let sponsor = Math.floor(Math.random() * (10000 - 200 + 1) + 200);
        let val = ageFillter(sponsor)
        setSponsor(val)
        if (i === 30) {
          setShowSponsor(true)
          getCondition(val)
        }
      }, duration)
    }
  }
  function ageFillter(val) {
    // 6-14 
    let age = getAge()
    if (age < 6) {
      return Math.floor(val * 0.05)
    }
    if (age >= 14) {
      return val
    }
    return Math.floor((age - 6) / 8 * val)
  }

  function getCondition(value) {
    let conditions = {
      0: "(无)",
      500: "同行一名亲友",
      1000: ["同行两名亲友", "制定行程计划"],
      2000: ["同行三名亲人", "制定行程计划"],
      3500: ["同行四名亲人", "制定行程计划", "两日以上", "每少一人增加一天行程"],
      5000: ["同行四名亲人", "制定行程计划", "两日以上", "每少一人增加一天行程"],
      6500: ["同行五名亲人", "制定行程计划", "两日以上", "每少一人增加一天行程", "必须离开市行政范围"],
      8000: ["同行五名亲人", "制定行程计划", "三日以上", "每少一人增加一天行程", "必须离开省"],
      9500: ["同行六名亲人", "制定行程计划", "三日以上", "每少一人增加一天行程", "必须离开省"],
    }
    let condition = ''
    Object.keys(conditions).forEach(v => {
      if (value > v) {
        condition = conditions[v]
      }
    })
    setCondition(condition)
    return condition
  }

  return <div className="page random">
    {/* <Map className="random-map" width="100%" height="300px"></Map> */}
    <div className="page-content">
      <div className={`card random-box ${showDetails ? 'done' : ''}`} onClick={animation}>
        <div className="btn">{target.name || '随机已有目的地'}</div>
        {
          showDetails ? <div className="details">
            简介：{target.details}
          </div> : ''
        }
      </div>
      <div className={`card random-box ${showLocDetails ? 'done' : ''}`} onClick={randomLoc}>
        <div className="btn">{locDetails.name || '随机经纬度'}</div>
        {
          showLocDetails ? <div className="details">
            简介：{locDetails.details}
          </div> : ''
        }
      </div>
      <div className={`card random-box ${showSponsor ? 'done' : ''}`} onClick={randomSponsor}>
        <div className="btn">{sponsor ? `￥${sponsor}` : '随机赞助（￥0-10000）'}</div>
        {
          showSponsor ? <div className="details">
            {
              Array.isArray(condition) ?
                condition.map((c, i) => <div key={'c' + i}>条件{i + 1}: {c}</div>) :
                <div>条件：{condition}</div>
            }
          </div> : ''
        }
      </div>
    </div>
  </div>
}