import axios from "./request"
const luggageList = {
  getList({ type, userId, teamId }) {
    return axios.get("/luggageList/list", { params: { type, userId, teamId } })
  },
  delItem({id, type, userId, teamId}){
    return axios.get("/luggageList/del", { params: { id, type, userId, teamId } })
  },
  change({id, status, userId, teamId}){
    return axios.get("/luggageList/change", { params: { id, status, userId, teamId } })
  },
  changeAll({type, status, userId, teamId}){
    return axios.get("/luggageList/changeAll", { params: { type, status, userId, teamId } })
  },
  addItem({name, type, userId, teamId}){
    return axios.post("/luggageList/add", { name, type, userId, teamId })
  },
}
export default luggageList