import style from "./LineSelect.module.scss"
import { useState } from "react";
export default function LineSelect(props) {
  const [expand, setExpand] = useState(false)
  function itemClick(item) {
    props.onChange(item)
    setExpand(!expand)
  }
  function expandOption() {
    setExpand(!expand)
  }
  function showLabel(val) {
    for (let i = 0; i < props.option.length; i++) {
      if (props.option[i].value === val) {
        return props.option[i].label
      }
    }
  }

  return <div className={style.LineSelect}>
    <div className={`line-select ${props.align || 'vertical'}`}> {/**horizontal vertical */}
      <div className="ls-label">
        {props.require ? <span className="star">*</span> : ''}
        {props.label}</div>
      <div className="ls-value" style={props.valueStyle} onClick={expandOption}>{props.value ? showLabel(props.value) : <span style={{ color: "var(--gray-500)" }}>{props.placeholder}</span>}</div>
    </div>
    <div className={`options ${props.optionStyle} ${expand ? 'expand' : ''}`}>
      {props.value ? <div className="option clear" onClick={() => itemClick({ value: '', item: '' })}>-清空-</div> : null}
      {props.option && props.option.map(
        item => <div className="option" key={item[props.keys?.key || 'value']} style={item.style} onClick={() => itemClick(item)}>{item[props.keys?.label || 'label']}</div>
      )}
    </div>
  </div>
}