import "./List.scss"
import { useEffect, useState, useContext } from "react"
import Modal from "../../components/Modal/Modal"
import Toast from "../../components/Toast"
import LineInput from "../../components/LineInput/LineInput"

import LuggageListApi from "../../api/luggageList"

import Context from "../../context"

export default function List() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)

  const [user, setUser] = useState({})
  const [searchType, setSearchType] = useState('1')
  const [list, setList] = useState([])
  const [showAdd, setShowAdd] = useState(false)
  const [addItem, setAddItem] = useState({ name: '', status: '', type: '1', userId: '', teamId: '' })

  function getList() {
    LuggageListApi.getList({
      type: searchType,
      userId: user.id || JSON.parse(localStorage.getItem("user")).id,
      teamId: user.teamId || JSON.parse(localStorage.getItem("user")).teamId
    }).then(res => {
      res.data.forEach(v => v.active = false)
      setList(res.data)
    })
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")))
    getList()
  }, [])

  useEffect(() => {
    getList()
  }, [searchType])

  function changeAll(status) {
    LuggageListApi.changeAll({ type: searchType, status, userId: user.id, teamId: user.teamId }).then(res => {
      getList()
    })
  }
  function openAdd() {
    setShowAdd(true)
    setAddItem({
      name: '',
      status: '1',
      type: searchType,
      userId: user.id,
      teamId: user.teamId
    })
  }

  useEffect(() => {
    if (showAdd) {
      if (addItem.name) {
        removeCallback()
      }
      addCallback(() => { setShowAdd(false); removeCallback() }, () => {
        if (addItem.name) {
          LuggageListApi.addItem({ ...addItem }).then(() => {
            Toast.success('新增成功')
            getList()
            setShowAdd(false);
            removeCallback()
          })
        } else {
          Toast.error('不能为空')
        }
      })
    }
  }, [showAdd, addItem])

  function itemClick(item) {
    let params = { ...item }
    params.type = searchType
    params.status = item.status === '1' ? '2' : '1'
    LuggageListApi.change(params).then(res => {
      getList()
    })
  }

  function itemActive(item) {
    setList(list.map(v => {
      v.active = v.id === item.id ? !v.active : false
      return v
    }))
  }

  function delItem(item) {
    LuggageListApi.delItem({ ...item }).then(res => {
      getList()
      Toast.success("删除成功")
    })
  }


  return <div className="page list">
    <div className="card top">
      <div className="search">
        <div className={`type ${searchType === '1' ? 'active' : ''}`} onClick={() => setSearchType('1')}>个人</div>
        <div className={`type ${searchType === '2' ? 'active' : ''}`} onClick={() => setSearchType('2')}>团队</div>
      </div>
    </div>
    <div className="card operate">
      <div className="btn set-all" onClick={() => changeAll('2')}>全部</div>
      <div className="btn add" onClick={openAdd}><img alt="" src={require("../../assets/cute/icons8-plus.gif")} /></div>
      <div className="btn reset" onClick={() => changeAll('1')}>重置</div>
    </div>
    <div className="card list">
      {list.length ? list.map(item => <div className={`item ${item.status === '1' ? 'wait' : 'done'}`} key={item.id}>
        <div className="square" onClick={() => itemClick(item)}>
          {
            item.status === '1' ?
              <img src={require('../../assets/cute/icons8-circled-64.png')} /> :
              <img src={require('../../assets/cute/icons8-check-circle.gif')} />
          }
        </div>
        <div className="name" onClick={() => itemActive(item)}>{item.name}</div>
        <div className={`del ${item.active ? "active" : ''}`} onClick={() => delItem(item)}><img alt="" src={require("../../assets/cute/icons8-trash-can.gif")} /></div>
      </div>) : "未添加项目"}
    </div>

    <Modal show={showAdd} title="新增项" tips={{ ok: '新增', cancel: '返回' }}>
      <div className="add-form">
        <LineInput require label="名称" placeholder="请输入名称" value={addItem.name} onChange={(e) => setAddItem({ ...addItem, name: e.target.value })} align="horizontal"></LineInput>
      </div>
    </Modal>
  </div>
}