import "./Team.scss"
import TeamApi from "../../../api/team"
import { useContext, useEffect, useState } from "react"
import Modal from "../../../components/Modal/Modal"
import Radio from "../../../components/Radio/Radio"
import Toast from "../../../components/Toast"
import LineInput from "../../../components/LineInput/LineInput"
import DatePicker from "../../../components/DatePicker/DatePicker"

import Context from "../../../context"

export default function Team() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)

  const [info, setInfo] = useState({ guider: [], adventurer: [], apply: [], remark: '' })
  const [showCheckModal, setShowCheckModal] = useState(false)
  const [checkInfo, setCheckInfo] = useState({})
  const [showChangeModal, setShowChangeModal] = useState(false)
  const [changeItem, setChangeItem] = useState({})
  const [userRole, setUserRole] = useState("")
  const [baseInfo, setBaseInfo] = useState({ id: '', name: '', remark: '' })
  const [showChangeInfoModal, setShowChangeInfoModal] = useState(false)
  const [showChangeDateModal, setShowChangeDateModal] = useState(false)
  const [bdInfo, setBdInfo] = useState({ id: '', nickname: '', birthday: '' })

  const [expandRemark, setExpandRemark] = useState(false)

  function toggleRemark(e) {
    e.stopPropagation()
    setExpandRemark(!expandRemark)
  }

  async function getInfo() {
    let teamId = JSON.parse(localStorage.getItem('user')).team.id
    const res = await TeamApi.getInfo(teamId)
    // 分离 冒险者和引导者
    let guiderId = res.data.guider ? res.data.guider.split(",") : []
    let adventurerId = res.data.adventurer ? res.data.adventurer.split(",") : []
    let guider = []
    let adventurer = []
    res.data.users.forEach(v => {
      v.active = false
      if (guiderId.includes(v.id)) {
        guider.push(v)
      }
      if (adventurerId.includes(v.id)) {
        adventurer.push(v)
      }
    })
    res.data.guider = guider
    res.data.adventurer = adventurer
    res.data.apply.forEach(item => item.check = '2') // 初始化所有申请为拒绝
    setInfo(res.data)
  }
  useEffect(() => {
    getInfo()
    return () => {
    }
  }, [])

  function isGuider() {
    return info.guider.some(v => v.id === JSON.parse(localStorage.getItem('user')).id)
  }

  function showCheck(item) {
    if (!isGuider()) return
    setCheckInfo(item);
    setShowCheckModal(true);
  }
  useEffect(() => {
    if (showCheckModal) {
      addCallback(() => { setShowCheckModal(false); removeCallback() }, () => {
        TeamApi.check(checkInfo.id, checkInfo.check).then(res => {
          Toast.success('审核成功')
          setShowCheckModal(false);
          getInfo();
          removeCallback();
        })
      })
    }
  }, [showCheckModal, checkInfo])

  function checkRadioChange(item) {
    setCheckInfo({ ...checkInfo, check: item.value });
  }

  function toggleMember(item, role) {
    setChangeItem(item)
    setShowChangeModal(true)
    setUserRole(role)
  }

  useEffect(() => {
    if (showChangeModal) {
      addCallback(() => { setShowChangeModal(false); removeCallback() }, () => {
        TeamApi.change(changeItem.id, info.id).then(res => {
          Toast.success('切换成功')
          setShowChangeModal(false);
          getInfo();
          removeCallback();
        })
      })
    }
  }, [showChangeModal, changeItem])

  function showChangeBaseInfoModal() {
    if (isGuider()) {
      setBaseInfo({
        id: info.id,
        name: info.name,
        remark: info.remark
      })
      setShowChangeInfoModal(true)
    }
  }
  useEffect(() => {
    if (showChangeInfoModal) {
      addCallback(() => { setShowChangeInfoModal(false); removeCallback() }, () => {
        TeamApi.changeInfo(baseInfo).then(res => {
          Toast.success('修改成功')
          setShowChangeInfoModal(false);
          getInfo();
          removeCallback();
        })
      })
    }
  }, [showChangeInfoModal, baseInfo])

  function clickMember(item, type) {
    if (!isGuider()) return
    let key, otherKey
    if (type === 1) {
      key = "guider"
      otherKey = "adventurer"
    } else {
      key = "adventurer"
      otherKey = "guider"
    }
    info[key].forEach(v => {
      if (v.id === item.id) {
        v.active = true
      } else {
        v.active = false
      }
    })
    info[otherKey].forEach(v => v.active = false)
    setInfo({ ...info })
  }

  function editMember(item, key) {
    setBdInfo({
      id: item.id,
      nickname: item.nickname,
      birthday: item.birthday
    })
    setShowChangeDateModal(true)
  }
  useEffect(() => {
    if (showChangeDateModal) {
      addCallback(() => { setShowChangeDateModal(false); removeCallback() }, () => {
        TeamApi.changeMemberBirthday({
          userId: bdInfo.id,
          teamId: JSON.parse(localStorage.getItem('user')).team.id,
          birthday: bdInfo.birthday
        }).then(res => {
          Toast.success('修改成功')
          setShowChangeDateModal(false);
          getInfo();
          removeCallback();
        })
      })
    }
  }, [showChangeDateModal, bdInfo])

  return <div className="page team">
    <div className="card info" onClick={showChangeBaseInfoModal}>
      <div className="name"><span>{info.name}</span><div className="id">团队ID: <strong>{info.id}</strong></div></div>
      <div className={`remark ${expandRemark ? 'expand' : ''}`}>
        {expandRemark ? info.remark : info.remark.slice(0, 55) || '团队还没有目标或简介'} {info.remark.length > 55 && <span style={{ float: 'right' }} onClick={toggleRemark}>{expandRemark ? "︿" : "﹀"}</span>}
      </div>
    </div>
    <div className="card member">
      <div className="title">引导者</div>
      <div className="list">
        {info.guider.length === 0 ?
          <div className="no-data">无</div> :
          info.guider.map(item => <div className={`user ${item.active ? 'active' : ''}`} key={item.id} onClick={() => clickMember(item, 1)}>
            <div className="avatar"><img src={item.avatar || require('../../../assets/cloud/icons8-rubber-duck-100.png')} alt="" /></div>
            <div className="name">{item.nickname}</div>
            <div className="email">({item.email})</div>
            {item.active ? <div className="btn">
              <img onClick={() => toggleMember(item, 'guider')} src={require('../../../assets/cute/icons8-exchange-64.png')} alt="" />
              <img onClick={() => editMember(item, 'guider')} src={require('../../../assets/cute/icons8-edit.gif')} alt="" />
            </div> : null}
          </div>)}
      </div>
    </div>
    <div className="card member">
      <div className="title">冒险家</div>
      <div className="list">
        {info.adventurer.length === 0 ?
          <div className="no-data">无</div> :
          info.adventurer.map(item => <div className={`user ${item.active ? 'active' : ''}`} key={item.id} onClick={() => clickMember(item, 2)} >
            <div className="avatar"><img src={item.avatar || require('../../../assets/cloud/icons8-rubber-duck-100.png')} alt="" /></div>
            <div className="name">{item.nickname}</div>
            <div className="email">({item.email})</div>
            {item.active ? <div className="btn">
              <img onClick={() => toggleMember(item, 'adventurer')} src={require('../../../assets/cute/icons8-exchange-64.png')} alt="" />
              <img onClick={() => editMember(item, 'adventurer')} src={require('../../../assets/cute/icons8-edit.gif')} alt="" />
            </div> : null}
          </div>)}
      </div>
    </div>
    {
      isGuider() && <div className="card apply">
        <div className="title">
          申请列表
        </div>
        <div className="list">
          {info.apply.length === 0 ?
            <div className="no-data">无</div> :
            info.apply.map(item => <div className="user" key={item.id} onClick={() => showCheck(item)}>
              <div className="avatar"><img src={item.avatar || require('../../../assets/cloud/icons8-rubber-duck-100.png')} alt="" /></div>
              <div className="name">{item.nickname}</div>
            </div>)}
        </div>
        <Modal title="审核：加入团队" show={showCheckModal} tips={{ ok: '确认', cancel: '返回' }}>
          <div className="check-modal">
            <div className="avatar"><img src={checkInfo.avatar || require("../../../assets/cloud/icons8-rubber-duck-100.png")} alt="" /></div>
            <p>{checkInfo.nickname}</p>
            <Radio value={checkInfo.check} option={[{ label: '同意', value: '1', color: 'var(--primary)' }, { label: '拒绝', value: '2', color: 'var(--warning)' }]} onChange={checkRadioChange}></Radio>
          </div>
        </Modal>
      </div>
    }

    <Modal title={`切换为：${userRole === 'guider' ? '冒险家' : '引导者'}`} show={showChangeModal} tips={{ ok: '确认', cancel: '返回' }}>
      <div className="check-modal">
        <div className="avatar"><img src={changeItem.avatar || require("../../../assets/cloud/icons8-rubber-duck-100.png")} alt="" /></div>
        <p>{changeItem.nickname}</p>
        <div style={{ marginTop: "16px" }}>是否将他/她设置为<span style={{ fontWeight: 600, color: "var(--primary)", margin: '0 8px', }}>{userRole === 'guider' ? '冒险家' : '引导者'}</span>?</div>
      </div>
    </Modal>
    <Modal title="修改团队信息" show={showChangeInfoModal} tips={{ ok: '确认', cancel: '返回' }}>
      <div className="change-info">
        <LineInput label="团队名称" value={baseInfo.name} placeholder="请输入团队名称" onChange={(e) => setBaseInfo({ ...baseInfo, name: e.target.value })}></LineInput>
        <LineInput label="团队备注" value={baseInfo.remark} placeholder="请输入团队备注" onChange={(e) => setBaseInfo({ ...baseInfo, remark: e.target.value })}></LineInput>
      </div>
    </Modal>
    <Modal title={`修改成员 ${bdInfo.nickname} 的生日`} show={showChangeDateModal} tips={{ ok: '确认', cancel: '返回' }}>
      <div className="change-info">
        <DatePicker label="成员生日" styleType="line" date={bdInfo.birthday} onChange={date => setBdInfo({ ...bdInfo, birthday: date?.string })}></DatePicker>
      </div>
    </Modal>
  </div>
}