export const currentType = {
  '1': {
    name: "存款",
    color: "#a6dfa3",
  },
  '2': {
    name: "取款",
    color: "#f6dcce",
  },
  '3': {
    name: "提现",
    color: "#faa4a0",
  },
  '4': {
    name: "结息",
    color: "#c7ede6",
  },
  '5': {
    name: "定期转活期",
    color: "#21aa7f",
  },
  '6': {
    name: "借贷到账",
    color: "#f21a0f",
  },
  '7': {
    name: "借贷还款",
    color: "#d96b30",
  },
}


export const currentWithdraw = {
  '1': '现金',
  '2': '银行卡',
  '3': '微信',
  '4': '支付宝',
  '5': '代管人',
}

export default {
  currentType,
  currentWithdraw,
}