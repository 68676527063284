
import "./Withdraw.scss"
import TagSelect from "../../../components/TagSelect/TagSelect"
import TitleBar from "../../../components/TitleBar/TitleBar"
import LineInput from "../../../components/LineInput/LineInput"
import Toast from "../../../components/Toast"

import BankApi from "../../../api/bank"

import Context from "../../../context"

import { useContext, useEffect, useState } from "react"
import bankUtils from "../../../utils/bank"

export default function Withdraw() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)

  const [user, setUser] = useState({ id: '' })
  const [options, setOptions] = useState([])
  const [selectedType, setSelectedType] = useState({ id: '1', name: '现金' })
  const [overage, setOverage] = useState('') // 余额
  const [params, seParams] = useState({ teamId: '', userId: '', type: '', amount: '', remark: '' }) // 提现金额

  function getDrawType() {
    setOptions([
      { id: '1', name: '现金' },
      { id: '2', name: '银行卡' },
      { id: '3', name: '微信' },
      { id: '4', name: '支付宝' },
      { id: '5', name: '代管人' },
    ])
  }

  function getDate() {
    BankApi.getCurrent(user.id || JSON.parse(localStorage.getItem('user')).id).then(res => {
      setOverage(res.data)
    })
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))
    getDrawType()
    getDate()
    seParams({
      ...params,
      userId: user.id || JSON.parse(localStorage.getItem('user')).id,
      teamId: user.teamId || JSON.parse(localStorage.getItem('user')).teamId,
    })
    // 组件卸载时，清除提交事件
    return () => {
      removeCallback();
    }
  }, [])
  useEffect(() => {
    if (params.amount) {
      removeCallback();
    }
    addCallback(null, () => {
      BankApi.withdraw(params).then(() => {
        getDrawType()
        getDate()
        seParams({ ...params, type: '', amount: '', remark: '' })
        Toast.success('申请已提交，请等待审核结果')
      })
    })
  }, [params])

  function selectItemClick(item) {
    setSelectedType(item)
    seParams({ ...params, type: item.id })
  }

  return <div className="page withdraw">
    <div className="card type">
      <TagSelect label="到账方式" selected={selectedType} itemClick={selectItemClick} options={options} defaultExpand={true}></TagSelect>
    </div>
    <TitleBar title="提现"></TitleBar>
    <div className="card operate">
      <div>提现金额: § {bankUtils.getUnit(params.amount)}</div>
      <LineInput label="§" align="horizontal" value={params.amount} type='number' placeholder="请输入提现金额" onChange={(e) => seParams({ ...params, amount: e.target.value })}></LineInput>
      <div className="info">当前活期账户余额: <span className="overage">§{bankUtils.moneyFormat(overage)}</span>, <span className="btn" onClick={(e) => seParams({ ...params, amount: overage })}>全部提现</span></div>
      <div>汇率兑换结果：￥{params.amount / 100} {bankUtils.getUnit(params.amount / 100)}</div>
      <div>预估手续费：§{(Math.ceil(params.amount / 1000))} &lt;--&gt; ￥{(params.amount / 1000 / 100).toFixed(2)} ，预估不一定准确，以最终扣取数量为准</div>
      <br />
      <LineInput label="备注" value={params.remark} placeholder="请输入提现备注" onChange={(e) => seParams({ ...params, remark: e.target.value })}></LineInput>
      <div className="rate">提现汇率: §{bankUtils.moneyFormat(100)} = ￥1</div>
      <div className="rate">提现手续费: 1‰</div>
    </div>
  </div>
}