import style from "./TabBar.module.scss"
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react"
import { debounce } from "../../utils/tools.js"
import Context from "../../context"

export default function TabBar() {
  let navigate = useNavigate();

  let { doCallback, clearCallback } = useContext(Context)

  // 返回按钮
  function back(e) {
    e.stopPropagation()
    if (window.backFnList.length) {
      doCallback('back')
    } else {
      if (window.location.hash === '#/') {
        return
      }
      navigate(-1)
    }
  }
  // 回到首页
  function goHome(e) {
    e.stopPropagation()
    clearCallback()
    navigate("/", { replace: true })
  }

  const okCallback = debounce(() => {
    doCallback('ok')
  }, 600)
  // 确认按钮
  function confirm(e) {
    e.stopPropagation()
    if (window.okFnList.length) {
      okCallback()
    }
  }
  function onEnter(e) {
    if (e.key === 'Enter') {
      confirm(e)
    }
  }
  useEffect(() => {
    window.addEventListener("keyup", onEnter)
    return () => {
      window.removeEventListener('keyup', onEnter)
    }
  }, [])

  return (
    <div className={style.tabbar}>
      <div className="bar" onClick={confirm}>
        <img src={require('../../assets/cute/icons8-done-100.png')} alt="" />
      </div>
      <div className="bar" onClick={goHome}>
        <img src={require('../../assets/cute/icons8-menu-100.png')} alt="" />
      </div>
      <div className="bar" onClick={back}>
        <img src={require('../../assets/cute/icons8-back-100.png')} alt="" />
      </div>
    </div>
  )
}