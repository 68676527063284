import "./Travel.scss"
import { Link } from "react-router-dom"

export default function Travel() {
  return <div className="page travel">
    <div className="travel-route">
      <Link to='/travel/random'>
        <div className="card">
          <img src={require('../../assets/cute/icons8-accuracy.gif')} alt="" />
          <div className="label">随机目的地</div>
        </div>
      </Link>
      <Link to='/travel/plan'>
        <div className="card">
          <img src={require('../../assets/cute/icons8-cursor.gif')} alt="" />
          <div className="label">自选目的地</div>
        </div>
      </Link>
    </div>
  </div>
}