
import "./Proxy.scss"
import TagSelect from "../../../components/TagSelect/TagSelect"
import TitleBar from "../../../components/TitleBar/TitleBar"
import LineInput from "../../../components/LineInput/LineInput"
import Cascader from "../../../components/Cascader/Cascader"
import Toast from "../../../components/Toast"
import Modal from "../../../components/Modal/Modal"

import BankApi from "../../../api/bank"
import TeamApi from "../../../api/team"

import Context from "../../../context"

import { useContext, useEffect, useState } from "react"
import bankUtils from "../../../utils/bank"

export default function Withdraw() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)

  const [user, setUser] = useState({ id: '' })
  const [showFunctionModal, setShowFunctionModl] = useState(false)
  const [showCreateModal, setShowCreateModl] = useState(false)
  const [createInfo, setCreateInfo] = useState({userId:"", teamId:"", operator:"", amount:"", remark: ""})
  const [list, setList] = useState([])
  const [teams, setTeams] = useState([])

  function getTeam() {
    let teamId = user.teamId || JSON.parse(localStorage.getItem('user')).teamId
    TeamApi.getInfo(teamId).then(res=>{
      res.data.users.forEach((user)=>{
        user.isSelected = false
      })
      setTeams(res.data.users)
    })
  }

  function getList(){
    BankApi.getProxyList(user.teamId || JSON.parse(localStorage.getItem('user')).teamId).then(res=>{
      setList(res.data)
    })
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))
    setCreateInfo({
      ... createInfo,
      operator: user.id || JSON.parse(localStorage.getItem('user')).id,
      teamId: user.teamId || JSON.parse(localStorage.getItem('user')).teamId
    })
    getTeam()
    getList()
  }, [])

  function showDetail(e){
    e.stopPropagation()
    setShowFunctionModl(true)
  }

  useEffect(() => {
    if(showFunctionModal){
      addCallback(()=>{setShowFunctionModl(false); removeCallback()},()=>{setShowFunctionModl(false); removeCallback()})
    }
  }, [showFunctionModal])

  function createProxy(){
    setShowCreateModl(true)
  }

  useEffect(() => {
    if(showCreateModal){
      addCallback(()=>{setShowCreateModl(false); removeCallback()},()=>{
        console.log(createInfo);
        BankApi.createProxy({...createInfo}).then(res=>{
          Toast.success("创建成功，等待审核")
          setShowCreateModl(false);
          removeCallback();
        })
      })
    }
  }, [showCreateModal, createInfo])

  function userClick(member){
    teams.forEach(u=>{
      if(u.id===member.id){
        u.isSelected = true
        console.log(member, member.id);
        setCreateInfo({...createInfo, userId: member.id})
      }else{
        u.isSelected = false
      }
    })
    setTeams([...teams])
  }

  function statusAdapter(status){
    return {
      '0': <span className='wait'>待审核</span>,
      '1': <span className='success'>已存入</span>,
      '2': <span className='fail'>未通过</span>
    }[status]
  }

  return <div className="page proxy">
    <div className="card add" onClick={createProxy}>
      <img alt="" src={require("../../../assets/cute/icons8-plus.gif")}></img>
      <div className="detail-btn" onClick={showDetail}>说明</div>
    </div>
    <TitleBar title="代存"></TitleBar>
    <div className="list">
      {list.map(proxy=><div className="card item" key={proxy.id}>
        <div className="amount">
          <span className="rmb">￥{bankUtils.moneyFormat(proxy.amount/100)}</span>
          <span className="transform">»»»</span>
          <span className="coin">§{bankUtils.moneyFormat(proxy.amount)}</span>
        </div>
        <div className="text">
          <div className="time">{proxy.updatedAt}</div>
          <div><span className="operator">{proxy.operatorInfo.nickname}</span> 为 <span className="target">{proxy.userInfo.nickname}</span> 代存</div>
        </div>
        <div className="status"><span>{statusAdapter(proxy.status)}</span>  <span>审核人: { proxy.checkerInfo?proxy.checkerInfo.nickname:''}</span></div>
      </div>)}
    </div>
    <Modal show={showCreateModal} title="新建代办" tips={{ cancel: '返回', ok: '确认' }}>
      <div className="create-modal">
        <div className="team-member">
          {teams.map(user=><div className={`user ${user.isSelected?'active':''}`} key={user.id} onClick={()=>userClick(user)}>
            <img src={user.avatar || require('../../../assets/cute/icons8-google-photos-256.png')} alt="" />
            <div>{user.nickname}</div>
          </div>)}
        </div>
        <LineInput align="horizontal" label="金额 §" value={createInfo.amount} type='number' placeholder="请输入借贷金额" onChange={(e) => setCreateInfo({ ...createInfo, amount: e.target.value })}></LineInput>
        <LineInput align="horizontal" label="备注" value={createInfo.remark} placeholder="请输入备注" onChange={(e) => setCreateInfo({ ...createInfo, remark: e.target.value })}></LineInput>
      </div>
    </Modal>
    <Modal show={showFunctionModal} title="待办功能说明" tips={{ cancel: '返回', ok: '返回' }}>
      <div className="protocol">
        <h1>代存功能说明</h1>
        <h2>一、定义</h2>
        <p>银行：本系统金币池银行，以下简称银行</p>
        <p>用户：发起代存方，以下简称您</p>
        <h2>二、协议确认与补充、修改</h2>
        <p>本协议包括协议正文及银行已发布的或将来可能发布的各类规则（含业务规范、通知、公告等）。所有上述各类规则等为本协议不可分割的组成部分，与本协议正文具有同等法律效力。如上述各类规则等与本协议有冲突，则以后发布者为准。您可以在当前页面中查阅相关协议条款。银行根据业务发展对本协议内容进行变更的，将通过本页面发布，不再逐一通知每位用户。如果您不接受该变更，有权不适用此服务；如果在上述变更发布后，您继续使用服务，视为您接受该变更。</p>
        <h2>三、服务及交易规则</h2>
        <p>3.1 使用本服务则表示您接受后续规则。</p>
        <p>3.2 代存功能主要用于帮助(冒险家)将现金直接存入本系统银行。</p>
        <p>3.3 代存只支持存储到(冒险家)的活期账户。</p>
        <p>3.4 代存需要另一位(引导者审核)。</p>
        <p>3.5 交易因数据原因出现问题的，需联系系统管理员找回数据，如数据无法找回的，按历史数据推断补偿，补偿方式最终解释权归银行系统所有。</p>
        <p>3.6 其余未尽事项，以银行解释为准。</p>
        <h2>四、其他</h2>
        <p>4.1 银行已提请您特别注意有关免除、限制银行责任，和银行单方面拥有某些权利以及增加您义务或免除、限制您权利的条款，并已应您的要求对上述条款予以充分解释说明，各方对本协议条款的理解完全一致，并自愿接受约束。</p>
        <p>4.2 若您与银行之间发生争议，由双方协商解决。协商不成的，就算了，毕竟银行钱包都是我开的。</p>
        <p>4.3 本协议的成立、生效、履行、解释及纠纷解决，不适用于法律，仅适用金融惯例。</p>
        <p>4.4 本协议条款无论因何种原因部分无效或不可执行，都不影响本协议其他条款的效力。</p>
        <p>4.5 本协议中未尽事宜，按照金融惯例办理。</p>
        <p>4.6 本协议中出现的金融惯例一词的解释权归银行所有。</p>
      </div>
    </Modal>
  </div>
}