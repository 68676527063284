import style from "./Radio.module.scss"
export default function Radio(props) {
  return <div className={style.radios}>
    {
      props.option.map(item => <div className={`radio ${item.value === props.value ? 'active' : ''}`} key={item.value} onClick={() => props.onChange(item)}
        style={{ background: item.value === props.value && item.color }}>
        <div className="icon"></div>
        <div className="label">{item.label}</div>
      </div>)
    }
  </div>
}