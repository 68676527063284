import axios from "./request"
const user = {
  signin(email, psw) {
    return axios.post("/user/login", { email, psw })
  },
  signup(nickname, code, email, psw) {
    return axios.post("/user/register", { nickname, code, email, psw })
  },
  changePsw({ id, old, psw }) {
    return axios.post("/user/changepsw", { id, old, psw })
  },
  getInfo(id) {
    return axios.get(`/user/getInfo/${id}`)
  },
  changeInfo({ id, nickname, motto, birthday }) {
    return axios.post(`/user/changeInfo`, { id, nickname, motto, birthday })
  },
  sendEmailCode(email, type) {
    return axios.get(`/email/send`, { params: { email, type } })
  },
  forget(email, code, psw) {
    return axios.post("/user/forget", { email, code, psw })
  },
}
export default user