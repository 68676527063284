import {
  LineSeries, Tooltip, XAxis, YAxis,
} from 'rough-charts'

import colors from '../../styles/colors'

export default function Line(props) {
  return <>
    <XAxis dataKey={props.option.xkey || "name"}  fontSize={props.option.fontSize || 12}/>
    <YAxis tickSize={props.option.tickSize || 3} fontSize={props.option.fontSize || 12}/>
    {
      props.keys.map((key, i)=><LineSeries
        key={key}
        dataKey={key}
        options={{
          stroke: colors[i%colors.length],
          strokeWidth: 2,
        }}
      />)
    }
    <Tooltip />
  </>
}