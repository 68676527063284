import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop'
import style from "./Clipper.module.scss"
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from '../../hooks/useDebounceEffect'

import { useState, useRef } from 'react'

export default function Clipper(props) {
  const [src, setSrc] = useState('')
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setSrc(reader.result.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }
  function onImageLoad(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget || e.target;
    let w
    if (width > height) {
      w = (height / width) * window.innerWidth * 0.7
    } else {
      w = window.innerWidth * 0.7
    }
    if (width < window.innerWidth * 0.7) {
      w = width
    }
    setCrop(centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: w - 10,
          height: w - 10,
        },
        1 / 1,
        w,
        w,
      ),
      w,
      w,
    )
    )
  }

  useDebounceEffect(() => {
    if (
      completedCrop?.width &&
      completedCrop?.height
    ) {
      preview(imgRef.current, previewCanvasRef.current, completedCrop)
    }
  }, [completedCrop])

  function base64ToFile(dataurl, filename) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }

  function preview(image, canvas, crop) {
    canvas.width = 120
    canvas.height = 120
    const ctx = canvas.getContext('2d')
    const scaleX = 120 / crop.width
    const scaleY = 120 / crop.height
    const imageWidth = image.width * scaleX
    const imageHeight = image.height * scaleY
    const offsetLeft = - Math.abs(crop.x * scaleX)
    const offsetTop = - Math.abs(crop.y * scaleY)
    ctx.drawImage(image, offsetLeft, offsetTop, imageWidth, imageHeight);
    let base64 = canvas.toDataURL("image/png")
    props.getBase64 && props?.getBase64(base64)

    let name = "avatar-" + new Date().getTime() + ".png"; // 定义文件名字（例如：cober.png）
    let file = base64ToFile(base64, name); // 调用base64转图片方法

    props.getFile && props.getFile(file)
  }

  return <div className={style.Clipper} >
    <ReactCrop
      crop={crop}
      onChange={c => setCrop(c)}
      onComplete={(c) => setCompletedCrop(c)}
      aspect={1 / 1}
    >
      <img ref={imgRef} src={src} onLoad={onImageLoad} />
    </ReactCrop>
    <div className='operate'>
      <canvas ref={previewCanvasRef} width="120" height="120"></canvas>
      <div className='btn'>
        <div className="txt">选择图片</div>
        <input type="file" accept="image/*" onChange={onSelectFile} />
      </div>
    </div>
  </div>
}