
import { useState, useContext, useEffect } from "react"
import "./Fixed.scss"
import TitleBar from "../../../components/TitleBar/TitleBar"
import Chart from "../../../components/Chart/Chart"
import Modal from "../../../components/Modal/Modal"
import LineInput from "../../../components/LineInput/LineInput"
import LineSelect from "../../../components/LineSelect/LineSelect"
import Toast from "../../../components/Toast"

import BankApi from "../../../api/bank"
import WalletApi from "../../../api/wallet"

import Context from "../../../context"

import bankUtils from "../../../utils/bank"

export default function Fixed() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)
  const [user, setUser] = useState({})
  const [total, setTotal] = useState('')
  const [lastDate, setLastDate] = useState(new Date(new Date() + 10 * 365 * 30 * 24 * 60 * 60 * 1000))
  const [chartData, setChartData] = useState([])
  const [showChart, setShowChart] = useState(false)
  const [fixedList, setFixedList] = useState([])
  const [fixedInfo, setFixedInfo] = useState([])
  const [addInfo, setAddInfo] = useState({
    userId: "",
    amount: "",
    endTime: "",
    endType: "",
    remark: "",
  })
  const [rete, setRete] = useState(0)
  const [wallet, setWallet] = useState("")
  const [showModal, setShowModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showTipsModal, setShowTipsModal] = useState(false)

  function getWallet() {
    WalletApi.getUserCoin(user.id || JSON.parse(localStorage.getItem('user')).id).then(res => {
      setWallet(res.data)
    })
  }
  function getFixedList() {
    BankApi.getFixedList(user.id || JSON.parse(localStorage.getItem("user")).id).then(res => {
      setFixedList(res.data)
      let newTotal = 0
      res.data.forEach(item => {
        newTotal += (Number(item.amount) + Number(item.interest))
        if (new Date(item.endTime) < new Date(lastDate)) {
          setLastDate(new Date(item.endTime))
        }
      })
      setTotal(newTotal)
    })
  }

  function getFixedTotal() {
    BankApi.getFixedTotal(user.id || JSON.parse(localStorage.getItem("user")).id).then(res => {
      let arr = listFormat(res.data)
      setRete(res.rate)
      setChartData(arr);
      setShowChart(true);
    })
  }

  function listFormat(list) {
    let maxDate = list.length ? new Date(list[list.length - 1].month) : new Date()
    let maxMonth = maxDate.getMonth() + 1, maxYear = maxDate.getFullYear()
    if (maxMonth === 1) {
      maxMonth = 12
      maxYear = maxYear - 1
    } else {
      maxMonth = maxMonth - 1
    }
    let arr = []
    for (let i = 0; i < 12; i++) {
      let m = i >= maxMonth ? 12 + maxMonth - i : maxMonth - i
      if (m === 1) {
        m = maxYear
      }
      let item = list.find(v => new Date(v.month).getMonth() === m)
      let obj = {
        name: m + '',
        value: item ? Number(item.total) : 0,
        value1: 0
      }
      arr.unshift(obj)
    }
    for (let i = 0; i < arr.length; i++) {
      if (i > 0) {
        arr[i].value1 = arr[i].value - arr[i - 1].value
      } else {
        arr[i].value1 = 0
      }
    }
    return arr
  }

  const option = {
    label: ['总额', '增长额']
  }

  useEffect(function () {
    setUser(JSON.parse(localStorage.getItem("user")))
    getFixedList()
    getFixedTotal()
    getWallet()
  }, [])

  function showAdd() {
    setShowAddModal(true);
    setAddInfo({
      userId: user.id || JSON.parse(localStorage.getItem("user")).id,
      amount: "",
      endTime: "",
      endType: "",
      remark: "",
    })
  }
  useEffect(() => {
    if (addInfo.amount) {
      removeCallback();
    }
    if (showAddModal) {
      addCallback(() => { setShowAddModal(false); removeCallback(); }, () => {
        // 提交数据
        if (addInfo.amount > wallet) {
          Toast.error("超过钱包可用余额")
          return
        }
        if (addInfo.amount < 10000) {
          Toast.error("存款金额不能小于一万金币 (§1,0000.00)")
          return
        }
        if (!addInfo.endTime || !addInfo.endType) {
          Toast.error("请选择存款期限和存款到期处理方式")
          return
        }
        BankApi.saveFixed(addInfo).then(res => {
          setShowAddModal(false);
          getFixedList()
          removeCallback();
        })
      })
    }
  }, [showAddModal, addInfo])

  function showProtocol() {
    setShowInfoModal(true);
    addCallback(() => {
      setShowInfoModal(false);
      removeCallback("back");
    }, () => {
      // 提交数据
      setShowInfoModal(false);
      removeCallback("ok");
    })
  }

  function isExpire(dateSting) {
    return new Date(dateSting + " 00:00:00").getTime() > new Date().getTime()
  }

  function getFutureDate(num) {
    let date = new Date();
    return `${date.getFullYear() + num}-${date.getMonth() + 1}-${date.getDate()}`
  }

  const endDateOption = [
    { value: getFutureDate(1), label: '一年' },
    { value: getFutureDate(2), label: '两年' },
    { value: getFutureDate(3), label: '三年' },
    { value: getFutureDate(4), label: '四年' },
    { value: getFutureDate(5), label: '五年' },
    { value: getFutureDate(6), label: '六年' },
    { value: getFutureDate(7), label: '七年' },
    { value: getFutureDate(8), label: '八年' },
    { value: getFutureDate(9), label: '九年' },
    { value: getFutureDate(10), label: '十年' },
    { value: getFutureDate(15), label: '十五年' },
    { value: getFutureDate(20), label: '二十年' },
    { value: getFutureDate(25), label: '二十五年' },
    { value: getFutureDate(30), label: '三十年' }
  ]
  const endTypeOption = [
    { value: '1', label: '自动转存' },
    { value: '2', label: '转为活期' },
    { value: '3', label: '不操作' }
  ]
  function getTypeLabel(val) {
    let arr = endTypeOption.find(v => v.value === val)
    return arr?.label
  }


  function itemClick(item) {
    setFixedInfo(item);
    setShowModal(true);
  }
  useEffect(() => {
    if (showModal) {
      addCallback(() => {
        setShowModal(false);
        removeCallback();
      }, () => {
        // 提交数据
        setShowTipsModal(true)
      })
    } else {
      removeCallback();
    }
  }, [showModal])

  useEffect(() => {
    if (showTipsModal) {
      addCallback(() => {
        setShowTipsModal(false);
        removeCallback();
      }, () => {
        // 提交数据
        BankApi.outFixed({ id: fixedInfo.id, userId: fixedInfo.userId }).then(res => {
          Toast.success("取出成功，请到钱包中查看")
          setShowTipsModal(false);
          getFixedList();
          removeCallback();
          setShowModal(false);
        })
      })
    }
  }, [showTipsModal, fixedInfo])

  function compareDate(date) {
    return new Date(date) > new Date()
  }

  return <div className="page fixed">
    {showChart ? <div className="chart">
      <Chart data={chartData} option={option}></Chart>
    </div> : null}

    <div className="short-info card">
      <div className="btn">
        <span className="protocol" onClick={showProtocol}>定期说明</span>
        <span className="save" onClick={showAdd}>存定期</span>
      </div>
      <div className="total">
        <span className="value">§{bankUtils.moneyFormat(total)}</span>
        <span className="label">总额 § {bankUtils.getUnit(total)}</span>
      </div>
      <div className="last">
        <span className="value">{new Date(lastDate).toLocaleDateString()}</span>
        <span className="label">最近到期</span>
      </div>
    </div>
    <TitleBar title="定期存款"></TitleBar>
    <div className="fixed-list">
      {
        fixedList.map(item => <div className="item" key={item.id} onClick={() => itemClick(item)}>
          <div className="title">
            <div className="id">{item.id.slice(0, 4) + "****" + item.id.slice(-4)}</div>
            <div className="amount">§{bankUtils.moneyFormat(item.amount)}</div>
          </div>
          <div className="info">
            <div className="block">
              <div className="label">当前利息</div>
              <div className="value">§{bankUtils.moneyFormat(item.interest)}</div>
            </div>
            <div className="block">
              <div className="label">利率</div>
              <div className="value">{item.rate}%</div>
            </div>
            <div className="block">
              <div className="label">存款时间</div>
              <div className="value">{item.createdAt.slice(0, 10)}</div>
            </div>
            <div className="block">
              <div className="label">到期时间</div>
              <div className={`value ${isExpire(item.endTime.slice(0, 10)) ? '' : 'expiry'}`}>{item.endTime.slice(0, 10)}</div>
            </div>
          </div>
        </div>)
      }
    </div>

    <Modal show={showInfoModal} title="定期存款协议及相关说明" tips={{ cancel: '返回', ok: '确认' }}>
      <div className="protocol">
        <h1>定期存取款说明</h1>
        <h2>一、定义</h2>
        <p>银行：本系统金币池银行，以下简称银行</p>
        <p>用户：发起存款方，以下简称您</p>
        <h2>二、协议确认与补充、修改</h2>
        <p>本协议包括协议正文及银行已发布的或将来可能发布的各类规则（含业务规范、通知、公告等）。所有上述各类规则等为本协议不可分割的组成部分，与本协议正文具有同等法律效力。如上述各类规则等与本协议有冲突，则以后发布者为准。您可以在当前页面中查阅相关协议条款。银行根据业务发展对本协议内容进行变更的，将通过本页面发布，不再逐一通知每位用户。如果您不接受该变更，有权不适用此服务；如果在上述变更发布后，您继续使用服务，视为您接受该变更。</p>
        <h2>三、服务及交易规则</h2>
        <p>3.1 使用本服务则表示您接受后续规则。</p>
        <p>3.2 存取款不需要审核，交易因数据原因中出现问题的，需联系银行找回数据，如数据无法找回的，按历史数据推断补偿，补偿方式最终解释权归银行所有。</p>
        <p>3.3 定期存款每月结息一次，结息时间为每月1日，结息利率以存款当天定期利率为准，结息计算方式为，利息=结息时该条存款金额×该条存款存款时的利率×该条存款已存款天数÷366。</p>
        <p>3.4 定期利率每日更新。更新方式为，在上一日利率基础上随机浮动0-10个基点，一基点为0.00001</p>
        <p>3.5 定期到期后，可选择自动转存和存为活期两种方式。自动转存表示自动再存储一期。存款时间长度与上期相同。</p>
        <p>3.6 定期存款未到期时，可以取出到钱包，但会收取§100手续费，并扣除剩余日期对应的存款利息作为违约金。即，存款一年，三个月后希望取出，则会扣除剩余9个月的利息作为违约金。</p>
        <p>3.7 存款和到期取款不收取手续费。</p>
        <p>3.8 其余未尽事项，以银行解释为准。</p>
        <h2>四、其他</h2>
        <p>4.1 银行已提请您特别注意有关免除、限制银行责任，和银行单方面拥有某些权利以及增加您义务或免除、限制您权利的条款，并已应您的要求对上述条款予以充分解释说明，各方对本协议条款的理解完全一致，并自愿接受约束。</p>
        <p>4.2 若您与银行之间发生争议，由双方协商解决。协商不成的，就算了，毕竟银行钱包都是我开的。</p>
        <p>4.3 本协议的成立、生效、履行、解释及纠纷解决，不适用于法律，仅适用金融惯例。</p>
        <p>4.4 本协议条款无论因何种原因部分无效或不可执行，都不影响本协议其他条款的效力。</p>
        <p>4.5 本协议中未尽事宜，按照金融惯例办理。</p>
        <p>4.6 本协议中出现的金融惯例一词的解释权归银行所有。</p>
      </div>
    </Modal>
    <Modal show={showAddModal} title="存定期" tips={{ cancel: '返回', ok: '存储' }}>
      <div className="add-form" style={{ minHeight: '50vh' }}>
        <div className="tips">
          <div className="rate">今日定期存款利率：<span>{rete}%</span></div>
          <div className="min">最低单笔存款额：<span>§{bankUtils.moneyFormat(10000)}</span></div>
          <div className="overage">钱包余额：<span>§{bankUtils.moneyFormat(wallet)}</span></div>
        </div>
        <div className="top">{bankUtils.getUnit(addInfo.amount)}</div>
        <LineInput align="horizontal" label="金额 §" value={addInfo.amount} type='number' placeholder="请输入存款金额" onChange={(e) => setAddInfo({ ...addInfo, amount: e.target.value })}></LineInput>
        <LineSelect align="horizontal" label="存款期限" value={addInfo.endTime} placeholder="请选择存款期限" optionStyle="tag" option={endDateOption} onChange={(item) => setAddInfo({ ...addInfo, endTime: item.value })}></LineSelect>
        <LineSelect align="horizontal" label="到期处理方式" value={addInfo.endType} placeholder="请选择到期处理方式" option={endTypeOption} onChange={(item) => setAddInfo({ ...addInfo, endType: item.value })}></LineSelect>
        <LineInput align="horizontal" label="备注" value={addInfo.remark} placeholder="请输入存款备注" onChange={(e) => setAddInfo({ ...addInfo, remark: e.target.value })}></LineInput>
      </div>
    </Modal >
    <Modal show={showModal} title={fixedInfo.name} tips={{ cancel: '返回', ok: '取款' }}>
      <div className="fixed-info">
        <div className="line amount">
          <div className="label">存款本金</div>
          <div className="value">§{bankUtils.moneyFormat(fixedInfo.amount)}</div>
        </div>
        <div className="line interest">
          <div className="label">当前利息</div>
          <div className="value">§{bankUtils.moneyFormat(fixedInfo.interest)}</div>
        </div>
        <div className="line rate">
          <div className="label">存款利率</div>
          <div className="value">{fixedInfo.rate}%</div>
        </div>
        <div className="line endTime">
          <div className="label">到期时间</div>
          <div className="value">{fixedInfo.endTime?.slice(0, 10)}</div>
        </div>
        <div className="line endType">
          <div className="label">到期处理方式</div>
          <div className="value">{getTypeLabel(fixedInfo.endType)}</div>
        </div>
        <div className="line remark">
          <div className="label">备注</div>
          <div className="value">{fixedInfo.remark}</div>
        </div>
      </div>
    </Modal>
    <Modal show={showTipsModal} title="提醒" tips={{ cancel: '返回', ok: '取款' }}>
      {
        showTipsModal && <div className="tips-info">
          <div>当前定期存款 {compareDate(fixedInfo.endTime) ?
            <span><strong>未到期</strong>，现在取出将<strong>扣除 §100</strong> 手续费，并 <strong>扣除剩余日期对应的利息</strong> 作为违约金</span> :
            <strong>已到期</strong>}，确认取出？</div>
        </div>
      }
    </Modal>
  </div >
}