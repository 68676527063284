import "./Achieve.scss"
import { useState, useEffect, useContext } from "react"
import TitleBar from "../../../components/TitleBar/TitleBar";
import Modal from "../../../components/Modal/Modal";
import Toast from "../../../components/Toast";
import LineInput from "../../../components/LineInput/LineInput";
import LineSelect from "../../../components/LineSelect/LineSelect";
import Cascader from "../../../components/Cascader/Cascader";

import AchievementApi from "../../../api/achievement"

import Context from "../../../context"

export default function Achieve() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)
  const [user, setUser] = useState([]);
  const [bread, setBread] = useState([]);
  const [stock, setStock] = useState([[]]);
  const [level, setLevel] = useState(0);
  const [sortStock, setSortStock] = useState([]); // 用于更新stock
  const [showDelModal, setShowDelModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [delItem, setDelItem] = useState({})
  const [type, setType] = useState('add')
  const [info, setInfo] = useState({})
  const [colorOptions] = useState([
    { label: '红色', value: '#faa4a0', style: { background: '#faa4a0' } },
    { label: '粉色', value: '#FFB996', style: { background: '#FFB996' } },
    { label: '紫色', value: '#cbccdf', style: { background: '#cbccdf' } },
    { label: '蓝色', value: '#9addef', style: { background: '#9addef' } },
    { label: '绿色', value: '#a6dfa3', style: { background: '#a6dfa3' } },
    { label: '白色', value: '#fffef9', style: { background: '#fffef9' } },
  ])
  // 获取成就分类
  function getList() {
    AchievementApi.getTree("1", user.teamId || JSON.parse(localStorage.getItem("user")).teamId).then(res => {
      updateStock(res.data)
    })
  }
  function updateStock(data) {
    let arr = [...stock]
    arr[0] = data
    let list = [...data]
    sortStock.forEach((sort, i) => {
      let cate = list.find(item => item.sort === sort)
      list = cate?.children
      if (list) {
        arr[i + 1] = list
      }
    })
    setStock(arr)
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")))
    setLevel(0)
    setBread([])
    getList()
  }, [])


  function breadClick(i) {
    let newLevel = i + 1
    let newStock = stock.slice(0, newLevel + 1)
    let newBread = bread.slice(0, newLevel)
    setStock(newStock)
    setBread(newBread)
    setLevel(newLevel)
  }

  function itemClick(item) {
    if (item.children && item.children.length) {
      setStock([...stock, item.children])
      setBread([...bread, item])
      setLevel(level + 1)
      setSortStock([...sortStock, item.sort])
      addCallback(() => {
        setStock(stock.slice(0, level + 1))
        setLevel(level)
        setBread(bread.slice(0, level + 1))
        removeCallback()
      }, null)
    }
  }

  function toAdd() {
    setType('add')
    setInfo({
      name: '',
      remark: '',
      categoryId: '',
      color: '#fffef9',
      morality: 0,
      courage: 0,
      perseverance: 0,
      knowledge: 0,
      wisdom: 0,
      physique: 0,
      communicate: 0,
      creativity: 0,
      implement: 0,
      coin: 0,
    })
    setShowModal(true)
  }
  function editItem(achieve) {
    setType('edit')
    setInfo(achieve)
    setShowModal(true)
  }
  function deleteItem(item) {
    setDelItem(item)
    setShowDelModal(true)
  }

  useEffect(() => {
    if (showDelModal) {
      addCallback(() => { setShowDelModal(false); removeCallback() }, () => {
        AchievementApi.del(delItem.id, user.teamId).then(() => {
          Toast.error("删除成功")
          getList()
          setShowDelModal(false);
          removeCallback();
        })
      })
    }
  }, [showDelModal, delItem])


  useEffect(() => {
    if (info.name) {
      removeCallback()
    }
    if (showModal) {
      addCallback(() => { setShowModal(false); removeCallback(); }, () => {
        if (!info.name || !info.categoryId) {
          Toast.error('参数错误，名称和分类必填')
          return
        }
        const { morality, courage, perseverance, knowledge, wisdom, physique, communicate, creativity, implement, coin } = { ...info }
        const obj = { morality, courage, perseverance, knowledge, wisdom, physique, communicate, creativity, implement, coin }
        if (Object.values(obj).some(v => v < 0)) {
          Toast.error("数据参数不能小于0")
          return
        }
        if (Object.values(obj).some(v => v > 100000)) {
          Toast.error("数据参数不能大于100000")
          return
        }
        if (type === 'add') {
          AchievementApi.add({ ...info, teamId: user.teamId }).then(() => {
            Toast.success('添加成功');
            getList();
            setShowModal(false);
            removeCallback();
          })
        }
        if (type === 'edit') {
          AchievementApi.edit({ ...info, teamId: user.teamId }).then(() => {
            Toast.success('修改成功');
            getList();
            setShowModal(false);
            removeCallback();
          })
        }
      })
    }
  }, [showModal, info])

  function isBelongTeamOrDefault(item) {
    return item.teamId === user.teamId
  }

  return <div className="page achieve">
    <div className="card add" onClick={toAdd}><img alt="" src={require("../../../assets/cute/icons8-plus.gif")}></img></div>
    <TitleBar title="成就列表"></TitleBar>
    <div className="list">
      <div className="breadcrumbs">
        <div onClick={() => breadClick(-1)} className={`bread-root ${bread.length ? "gray" : ""}`}><img alt="" src={require("../../../assets/gif/aircraft.gif")} /></div>
        {
          bread.map((b, i) => <div key={i}> | <span key={b.id + i} onClick={() => breadClick(i)}>{b.name}</span></div>)
        }
      </div>
      {stock[level].map(item => <div className="card item" key={item.id + level}>
        <div className={`title ${(item.achievements && item.achievements.length) ? 'has-child' : ''}`} onClick={() => itemClick(item)}>
          {item.pid === 1 ? <img alt="" className="a-logo" src={require(`../../../assets${item.icon}`)} /> : ''}
          {item.name}
        </div>
        {
          (item.achievements && item.achievements.length) ? <div className="achieve-list">
            {
              item.achievements.map(achieve => <div className="achieve-item" style={{ background: achieve.color }} key={achieve.id + 'a' + level}>
                <div className="achieve-title">{achieve.name}</div>
                <div className="achieve-operate">
                  {isBelongTeamOrDefault(achieve) ? <span className="edit" onClick={() => editItem(achieve)}><img alt="" src={require("../../../assets/cute/icons8-edit.gif")} /></span> : null}
                  {isBelongTeamOrDefault(achieve) ? <span className="del" onClick={() => deleteItem(achieve)}><img alt="" src={require("../../../assets/cute/icons8-trash-can.gif")} /></span> : null}
                </div>
              </div>)
            }
          </div> : ''
        }
      </div>)}
    </div>

    <Modal show={showDelModal} title="删除分类" tips={{ ok: '删除', cancel: '返回' }}>
      <span style={{ color: "var(--error)" }}>正在删除成就 <strong>{delItem.name}</strong> ，删除后不可找回，确认删除？</span>
    </Modal>
    <Modal show={showModal} title={`${type === 'edit' ? '编辑' : '新增'}成就`} tips={{ ok: '删除', cancel: '返回' }}>
      <div className="achieve-form">
        <LineInput require label="名称" placeholder="请输入名称" value={info.name} onChange={(e) => setInfo({ ...info, name: e.target.value })} align="horizontal"></LineInput>
        <Cascader require label="分类" placeholder="请选择，编辑时不选则不变" option={stock[0]} onChange={(item) => setInfo({ ...info, categoryId: item.id })} align="horizontal"></Cascader>
        <LineSelect require label="颜色" valueStyle={{ background: info.color }} option={colorOptions} placeholder="请选择颜色" value={info.color} onChange={(item) => setInfo({ ...info, color: item.value })} align="horizontal"></LineSelect>
        <LineInput label="备注" placeholder="请输入备注" value={info.remark} onChange={(e) => setInfo({ ...info, remark: e.target.value })} align="horizontal"></LineInput>
        <LineInput className="half-width" label="品德" placeholder="请输入品德值" value={info.morality} onChange={(e) => setInfo({ ...info, morality: e.target.value })} type="number" align="horizontal"></LineInput>
        <LineInput className="half-width" label="勇气" placeholder="请输入勇气值" value={info.courage} onChange={(e) => setInfo({ ...info, courage: e.target.value })} type="number" align="horizontal"></LineInput>
        <LineInput className="half-width" label="坚毅" placeholder="请输入坚毅值" value={info.perseverance} onChange={(e) => setInfo({ ...info, perseverance: e.target.value })} type="number" align="horizontal"></LineInput>
        <LineInput className="half-width" label="知识" placeholder="请输入知识值" value={info.knowledge} onChange={(e) => setInfo({ ...info, knowledge: e.target.value })} type="number" align="horizontal"></LineInput>
        <LineInput className="half-width" label="智慧" placeholder="请输入智慧值" value={info.wisdom} onChange={(e) => setInfo({ ...info, wisdom: e.target.value })} type="number" align="horizontal"></LineInput>
        <LineInput className="half-width" label="体魄" placeholder="请输入体魄值" value={info.physique} onChange={(e) => setInfo({ ...info, physique: e.target.value })} type="number" align="horizontal"></LineInput>
        <LineInput className="half-width" label="沟通" placeholder="请输入沟通值" value={info.communicate} onChange={(e) => setInfo({ ...info, communicate: e.target.value })} type="number" align="horizontal"></LineInput>
        <LineInput className="half-width" label="创造" placeholder="请输入创造值" value={info.creativity} onChange={(e) => setInfo({ ...info, creativity: e.target.value })} type="number" align="horizontal"></LineInput>
        <LineInput className="half-width" label="执行" placeholder="请输入执行值" value={info.implement} onChange={(e) => setInfo({ ...info, implement: e.target.value })} type="number" align="horizontal"></LineInput>
        <LineInput className="half-width" label="金币" placeholder="请输入金币奖励" value={info.coin} onChange={(e) => setInfo({ ...info, coin: e.target.value })} type="number" align="horizontal"></LineInput>
      </div>
    </Modal>
  </div>
}