import axios from "./request"
const upload = {
  avatar({ id, file }) {
    let formDatas = new FormData();
    formDatas.append('id', id)
    formDatas.append('file', file)
    return axios({
      method: "POST",
      url: "/upload/avatar",
      headers: {
        'Content-Type': "multipart/form-data",
      },
      //这部分非常重要，否则formdata会被转格式
      transformRequest: [function () {
        return formDatas;
      }],
      data: formDatas,
      params: formDatas,
    })
  },
}
export default upload