import style from "./Modal.module.scss"

export default function Modal({ children, ...props }) {
  return <div className={style.modal}>
    <div className={`modal-box ${props.show ? 'active' : ''}`}>
      <div className="modal-content">
        <div className="m-title">{props.title || "操作"}</div>
        <div className="m-content">
          {children}
        </div>
        <div className="m-bottom-tips">
          <span>{(props.tips && props.tips.ok) || 'ok'}</span>
          <span>{(props.tips && props.tips.cancel) || 'cancel'}</span>
        </div>
      </div>
    </div>
  </div>
}