import "./Destination.scss"
import { useState, useEffect, useContext } from "react"
import TitleBar from "../../../components/TitleBar/TitleBar";
import Modal from "../../../components/Modal/Modal";
import Toast from "../../../components/Toast";
import LineInput from "../../../components/LineInput/LineInput";
import LineTextarea from "../../../components/LineTextarea/LineTextarea";
import Cascader from "../../../components/Cascader/Cascader";

import DestinationApi from "../../../api/destination"

import Context from "../../../context"

export default function Destination() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)
  const [user, setUser] = useState([]);
  const [bread, setBread] = useState([]);
  const [stock, setStock] = useState([[]]);
  const [level, setLevel] = useState(0);
  const [sortStock, setSortStock] = useState([]); // 用于更新stock
  const [showDelModal, setShowDelModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [delItem, setDelItem] = useState({})
  const [type, setType] = useState('add')
  const [info, setInfo] = useState({})
  // 获取目的地分类
  function getList() {
    DestinationApi.getTree("2", user.teamId || JSON.parse(localStorage.getItem("user")).teamId).then(res => {
      updateStock(res.data)
    })
  }
  function updateStock(data) {
    let arr = [...stock]
    arr[0] = data
    let list = [...data]
    sortStock.forEach((sort, i) => {
      let cate = list.find(item => item.sort === sort)
      list = cate?.children
      if (list) {
        arr[i + 1] = list
      }
    })
    setStock(arr)
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")))
    setLevel(0)
    setBread([])
    getList()
  }, [])


  function breadClick(i) {
    let newLevel = i + 1
    let newStock = stock.slice(0, newLevel + 1)
    let newBread = bread.slice(0, newLevel)
    setStock(newStock)
    setBread(newBread)
    setLevel(newLevel)
  }

  function itemClick(item) {
    if (item.children && item.children.length) {
      setStock([...stock, item.children])
      setBread([...bread, item])
      setLevel(level + 1)
      setSortStock([...sortStock, item.sort])
      addCallback(() => {
        setStock(stock.slice(0, level + 1))
        setLevel(level)
        setBread(bread.slice(0, level + 1))
        removeCallback()
      }, null)
    }
  }

  function toAdd() {
    setType('add')
    setInfo({
      name: '',
      details: '',
      categoryId: '',
    })
    setShowModal(true)
  }
  function editItem(achieve) {
    setType('edit')
    setInfo(achieve)
    setShowModal(true)
  }
  function deleteItem(item) {
    setDelItem(item)
    setShowDelModal(true)
  }

  useEffect(() => {
    if (showDelModal) {
      addCallback(() => { setShowDelModal(false); removeCallback() }, () => {
        DestinationApi.del(delItem.id, user.teamId).then(() => {
          Toast.error("删除成功")
          getList()
          setShowDelModal(false);
          removeCallback();
        })
      })
    }
  }, [showDelModal, delItem])


  useEffect(() => {
    if (info.name) {
      removeCallback()
    }
    if (showModal) {
      addCallback(() => { setShowModal(false); removeCallback(); }, () => {
        if (!info.name || !info.categoryId) {
          Toast.error('参数错误，名称和分类必填')
          return
        }
        if (type === 'add') {
          DestinationApi.add({ ...info, teamId: user.teamId }).then(() => {
            Toast.success('添加成功');
            getList();
            setShowModal(false);
            removeCallback();
          })
        }
        if (type === 'edit') {
          DestinationApi.edit({ ...info, teamId: user.teamId }).then(() => {
            Toast.success('修改成功');
            getList();
            setShowModal(false);
            removeCallback();
          })
        }
      })
    }
  }, [showModal, info])

  function isBelongTeamOrDefault(item) {
    return item.teamId === user.teamId
  }

  return <div className="page destination">
    <div className="card add" onClick={toAdd}><img alt="" src={require("../../../assets/cute/icons8-plus.gif")}></img></div>
    <TitleBar title="目的地列表"></TitleBar>
    <div className="list">
      <div className="breadcrumbs">
        <div onClick={() => breadClick(-1)} className={`bread-root ${bread.length ? "gray" : ""}`}><img alt="" src={require("../../../assets/gif/aircraft.gif")} /></div>
        {
          bread.map((b, i) => <div key={i}> | <span key={b.id + i} onClick={() => breadClick(i)}>{b.name}</span></div>)
        }
      </div>
      {stock[level].map(item => <div className="card item" key={item.id + level}>
        <div className={`title ${(item.destination && item.destination.length) ? 'has-child' : ''}`} onClick={() => itemClick(item)}>
          {item.pid === 2 ? <img alt="" className="a-logo" src={item.icon && require(`../../../assets${item.icon}`)} /> : ''}
          {item.name}
        </div>
        {
          (item.destination && item.destination.length) ? <div className="destination-list">
            {
              item.destination.map(deste => <div className="destination-item" key={deste.id + 'a' + level}>
                <div className="destination-title">{deste.name}</div>
                <div className="destination-operate">
                  {isBelongTeamOrDefault(deste) ? <span className="edit" onClick={() => editItem(deste)}><img alt="" src={require("../../../assets/cute/icons8-edit.gif")} /></span> : null}
                  {isBelongTeamOrDefault(deste) ? <span className="del" onClick={() => deleteItem(deste)}><img alt="" src={require("../../../assets/cute/icons8-trash-can.gif")} /></span> : null}
                </div>
              </div>)
            }
          </div> : ''
        }
      </div>)}
    </div>

    <Modal show={showDelModal} title="删除分类" tips={{ ok: '删除', cancel: '返回' }}>
      <span style={{ color: "var(--error)" }}>正在删除目的地 <strong>{delItem.name}</strong> ，删除后不可找回，确认删除？</span>
    </Modal>
    <Modal show={showModal} title={`${type === 'edit' ? '编辑' : '新增'}目的地`} tips={{ ok: '删除', cancel: '返回' }}>
      <div className="achieve-form">
        <LineInput require label="名称" placeholder="请输入名称" value={info.name} onChange={(e) => setInfo({ ...info, name: e.target.value })} align="horizontal"></LineInput>
        <Cascader require label="分类" placeholder="请选择，编辑时不选则不变" option={stock[0]} onChange={(item) => setInfo({ ...info, categoryId: item.id })} align="horizontal"></Cascader>
        <LineTextarea label="简介" placeholder="请输入简介" value={info.details} onChange={(e) => setInfo({ ...info, details: e.target.value })} align="horizontal"></LineTextarea>
      </div>
    </Modal>
  </div>
}