export const walletCategory = {
  '1': '成就收入',
  '2': '商店消费',
  '3': '存活期',
  '4': '取活期',
  '5': '存定期',
  '6': '取定期',
  '7': '红包收入',
  '8': '红包支出'
}

export const walletIcon = {
  '1': '/cute/icons8-gg-100.png',
  '2': '/cute/icons8-online-store-64.png',
  '3': '/cute/icons8-receipt-64.png',
  '4': '/cute/icons8-cheque-64.png',
  '5': '/cute/icons8-safe-in-64.png',
  '6': '/cute/icons8-safe-out-64.png',
  '7': '/cute/icons8-foul-64.png',
  '8': 'icons8-cash-in-hand-64.png'
}

export default {
  walletCategory,
  walletIcon
}