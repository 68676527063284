// doc: https://beizhedenglong.github.io/rough-charts/?path=/docs/roughcharts--page

import { ChartProvider } from 'rough-charts'
import Line from './Line'
import colors from '../../styles/colors'

export default function Chart(props) {
  function getValueKeys(data) {
    return Object.keys(data[0]).filter(v => v.includes("value"))
  }
  function getMax(data) {
    let max = 0
    data.forEach(obj => {
      getValueKeys(data).forEach(key => {
        max = obj[key] > max ? obj[key] : max
      })
    })
    return max
  }
  function calcMarginLeft() {
    return 70 - (9 - String(getMax(props.data)).length) * 7
  }

  const labelsStyle = {
    paddingBottom: '10px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-around',
  }
  return <>
    <ChartProvider
      margin={{ left: calcMarginLeft(), bottom: 30 }}
      height={200}
      data={props.data}
    >
      <Line option={props.option} keys={getValueKeys(props.data)}></Line>
    </ChartProvider>
    {
      (props.option.label && props.option.label.length) && <div className='labels' style={labelsStyle}>
        {props.option.label.map((label, i) => (
          <div key={i} style={{ color: 'var(--gray-300)' }}>value{i ? i : ''}: <span style={{ color: colors[i] }}>{label}</span></div>
        ))}
      </div>
    }

  </>
}