import { useEffect, useState } from "react"
import style from "./TagSelect.module.scss"

export default function TagSelect(props) {
  const [expand,setExpand] = useState(false)
  function toggle(){
    setExpand(!expand)
  }
  function itemClick(item){
    if(item.id===props.selected.id) return
    props.itemClick(item)
  }
  useEffect(()=>{
    setExpand(Boolean(props.defaultExpand))
  },[])
  return <div className={style.tagSelect}>
    <div className={`tag-select ${expand?'expand':''}`}>
    <div className="select-bar" onClick={toggle}>
      <div className="label">{props.label}</div>
      <div className="value">{props.selected && props.selected.name || <span className="placeholder">请选择</span>}</div>
      <div className="arrow"><span></span><span></span></div>
    </div>
    <div className="select-options">
      {props.options && props.options.map(item=><div className={`tag ${item.id===props.selected.id?'selected':''}`} key={item.id} onClick={()=>itemClick(item)}>{item.name}</div>)}
    </div>
  </div>
  </div>
}