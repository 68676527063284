import { useEffect, useRef } from "react"
import style from "./LineInput.module.scss"

export default function LineInput(props) {
  const formRef = useRef(null)
  useEffect(() => {
    formRef.current.onsubmit = function (e) {
      e.preventDefault()
      props.onSubmit && props.onSubmit()
    };
  }, [formRef])
  return <div className={`${style.LineInput} ${props.className || ''}`}>
    <div className={`line-input ${props.align || 'vertical'}`}> {/**horizontal vertical */}
      <div className="li-label">
        {props.require ? <span className="star">*</span> : ''}
        {props.label}</div>
      <form ref={formRef} action=""><input type={props.type || 'text'} placeholder={props.placeholder} value={props.value} onChange={props.onChange} /></form>
    </div>
  </div>
}