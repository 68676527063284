import style from "./Cascader.module.scss"
import { useEffect, useState } from "react"

export default function Cascader(props) {
  const [stock, setStock] = useState([[]])
  const [result, setResult] = useState([])
  const [level, setLevel] = useState(0)
  const [expand, setExpand] = useState(false)
  const [childrenKey, setChildrenKey] = useState("")

  useEffect(() => {
    let stock = props.option.map(v => {
      v.selected = false
      return v
    })
    setStock([stock])
    setLevel(props.level || 0)
    setResult([])
  }, [props.option])

  useEffect(() => {
    setChildrenKey(props.childrenKey || 'children')
  }, [props.childrenKey])

  function selectOne(item) {
    let arr = [...result]
    arr[level] = item.name
    setResult(arr)
    stock[level].forEach(v => {
      if (v.id === item.id) {
        v.selected = true
      } else {
        v.selected = false
      }
      return v
    })

    setStock([...stock])

    props.onChange(item)
  }

  function inChildren(item) {
    if (item[childrenKey] && item[childrenKey].length) {
      let childen = item[childrenKey].map(v => {
        v.selected = false
        return v
      })
      stock[level].forEach(v => {
        if (v.id === item.id) {
          v.selected = true
        } else {
          v.selected = false
        }
        return v
      })
      setStock([...stock, childen])
      setLevel(level + 1)

      let arr = [...result]
      arr[level] = item.name
      setResult(arr)
    }
  }

  function back() {
    if (level > 0) {
      if (result[level]) {
        setResult(result.slice(0, -1))
      }
      if (stock[level]) {
        setStock(stock.slice(0, -1))
      }
      setLevel(level - 1)
    } else {
      setLevel(0)
      setStock([stock[0]])
      setResult([])
    }
  }

  return <div className={style.cascader}>
    <div className={`cascader-showbox ${props.align || 'vertical'}`}>

      <div className="label">
        {props.require ? <span className="star">*</span> : ''}
        {props.label}
      </div>
      <div className="result">
        <div className="str" onClick={() => setExpand(!expand)}>
          {result.length > 0 ? result.join(" / ") : <span style={{ color: "var(--gray-500)" }}>{props.placeholder}</span>}
        </div>
        {
          result.length > 0 && <div className="back" onClick={back}>
            <img src={require("../../assets/gif/icons8-恢复.gif")} alt="" />
          </div>
        }

      </div>
    </div>

    <div className={`select-list ${expand ? 'expand' : ''}`}>
      {
        stock[level].map(item => <div className={`item ${item.selected ? 'active' : ''}`} key={item.id} style={{ background: props.bg, color: props.color }}>
          <div className="name" onClick={() => selectOne(item)}>
            <div className="circle"></div>
            {item.name}
          </div>
          {(item[childrenKey] && item[childrenKey].length) ? <div className="arrow" onClick={() => inChildren(item)}></div> : null}
        </div>)
      }
      <div className="up-arrow" onClick={() => setExpand(false)}>︿</div>
    </div>
  </div>
}