
import "./Bill.scss";
import Chart from "../../../components/Chart/Chart";
import TitleBar from "../../../components/TitleBar/TitleBar";
import DatePicker from "../../../components/DatePicker/DatePicker";
import LineInput from "../../../components/LineInput/LineInput";
import LineSelect from "../../../components/LineSelect/LineSelect";
import Toast from "../../../components/Toast";
import Modal from "../../../components/Modal/Modal";
import bankUtils from "../../../utils/bank"

import Context from "../../../context"
import { useContext, useEffect, useState } from "react"
import { useDebounceEffect } from "../../../hooks/useDebounceEffect"

import BankApi from "../../../api/bank"

export default function Bill() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)
  const [user, setUser] = useState({})
  const [params, setParams] = useState({
    userId: '',
    billId: '',
    type: '',
    sort: 'desc',
    start: '',
    end: '',
    page: 1,
    size: 1000,
  })
  const [sortDesc, setSortDesc] = useState(true)
  const [list, setList] = useState([])
  const [info, setInfo] = useState({})
  const [showModal, setShowModal] = useState(false)

  const data = [
    { name: '7', value: 0 },
    { name: '8', value: 18 },
    { name: '9', value: 40 },
    { name: '10', value: 170 },
    { name: '11', value: 130 },
    { name: '12', value: 150 },
    { name: '2022', value: 260 },
    { name: '2', value: 400 },
    { name: '3', value: 330 },
    { name: '4', value: 420 },
    { name: '5', value: 250 },
    { name: '6', value: 500 },
  ]
  const option = {
    label: ['总资产']
  }
  function getBill() {
    BankApi.getBill(params).then(res => {
      setList(res.data)
    })
  }
  function changeSort(sort) {
    setParams({ ...params, sort: sort ? 'desc' : 'asc' })
    setSortDesc(sort)
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))
    getBill()
  }, [])


  useDebounceEffect(() => {
    if (params.end && params.start && new Date(params.end) < new Date(params.start)) {
      Toast.error("开始日期应小于结束日期")
      return
    }
    getBill()
  }, [params], 500)

  const types = [
    { value: '1', label: '活期存款' },
    { value: '2', label: '活期取款' },
    { value: '3', label: '提现申请' },
    { value: '4', label: '提现审核' },
    { value: '5', label: '活期结息' },
    { value: '6', label: '定期存款' },
    { value: '7', label: '定期取款' },
    { value: '8', label: '定期自动转存' },
    { value: '9', label: '定期转活期' },
    { value: '10', label: '定期结息' },
    { value: '11', label: '借款' },
    { value: '12', label: '还款' },
    { value: '13', label: '代办存款' }
  ]
  function getType(key) {
    let name = ''
    types.forEach(item => {
      if (item.value === String(key)) {
        name = item.label
      }
    })
    return name
  }
  function showDeital(item){
    setInfo({...item})
    setShowModal(true)
  }
  useEffect(()=>{
    if(showModal){
      addCallback(()=>{
        setShowModal(false); removeCallback()
      },()=>{
        setShowModal(false); removeCallback()
      })
    }
  },[showModal, info])

  return <div className="page bill">
    <div className="chart">
      {/* <div className="chart-container">
        <Chart data={data} option={option}></Chart>
      </div> */}
    </div>
    <div className="card search-box">
      <LineInput align="horizontal" label="编号" value={params.billId} type='number' placeholder="请输入账单编号" onChange={(e) => setParams({ ...params, billId: e.target.value })}></LineInput>
      <LineSelect align="horizontal" label="类型" value={params.type} placeholder="请输入类型" optionStyle="tag" option={types} onChange={(item) => setParams({ ...params, type: item.value })}></LineSelect>
      <div className="search-date">
        <DatePicker date={params.start} onChange={date => setParams({ ...params, start: date?.string })}></DatePicker>
        -
        <DatePicker date={params.end} onChange={date => setParams({ ...params, end: date?.string })}></DatePicker>
      </div>
    </div>
    <TitleBar title="个人账单" sort={sortDesc} changeSort={changeSort}></TitleBar>
    <div className="total-list">
      {list.map(item => <div className="item card" key={item.id} onClick={()=>showDeital(item)}>
        <div className="line amount">
          <div className="label">{getType(item.type)}</div>
          <div className="info">§{bankUtils.moneyFormat(item.amount)}</div>
        </div>
        <div className="line base">
          <div className="label">编号: {item.id}</div>
          <div className="info">{item.createdAt}</div>
        </div>
      </div>)}
    </div>
    
    <Modal show={showModal} title="记录详情" tips={{ cancel: '返回', ok: '返回' }}>
      <div className="bill-detials">
        <div className="line">
          <div className="label">类型</div>
          <div className="value">{getType(info.type)}</div>
        </div>
        <div className="line">
          <div className="label">金额</div>
          <div className="value">§{bankUtils.moneyFormat(info.amount)}</div>
        </div>
        <div className="line">
          <div className="label">手续费</div>
          <div className="value">§{bankUtils.moneyFormat(info.charge)}</div>
        </div>
        <div className="line">
          <div className="label">备注</div>
          <div className="value">{info.remark || "(无)"}</div>
        </div>
        <div className="line">
          <div className="label">时间</div>
          <div className="value">{info.createdAt}</div>
        </div>
      </div>
    </Modal>
  </div>
}