import style from "./SendEmail.module.scss"
import { useState, useEffect } from "react"
import Toast from "../../components/Toast/index"
import UserApi from "../../api/user"

const emailRegExp = /^[A-Za-z0-9\u4e00-\u9fa5\#\$_\&\-\!\+\=\*]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/
export default function SendEmail(props) {
  const [sec, setSec] = useState(0)
  useEffect(() => {
    let timer = null
    if (sec) {
      timer = setTimeout(() => {
        setSec(sec - 1)
      }, 1000)
    } else {
      timer && setTimeout(timer)
    }
  }, [sec])
  function sendEmail() {
    if (!sec) {
      if (!emailRegExp.test(props.email)) {
        Toast.error("邮箱格式不对")
        return
      }
      UserApi.sendEmailCode(props.email, props.type).then(res => {
        console.log(res)
        setSec(60)
      })
    }
  }
  return <div className={style.send} onClick={sendEmail}>{sec ? `${sec}s 后重发` : "发送验证码"}</div>
}

