import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import TabBar from "../components/TabBar/TabBar";

export default function Layout() {
  // localStorage.setItem('token',"123")
  let nevigate = useNavigate()

  useEffect(() => {
    // 没有token则回到登录页
    if (!localStorage.getItem("tk")) {
      nevigate("/signin")
    }
  }, [new Date()])
  return <div>
    <Outlet></Outlet>
    <TabBar></TabBar>
  </div>
}