import "./Bank.scss"
import { Link } from "react-router-dom"
import PageIcon from "../../components/PageIcon/PageIcon"

export default function Bank() {
  return <div className="page bank">
    {/* <Link to='/achievement'>
      <PageIcon src={require('../../assets/cloud/icons8-勋章-100.png')} title="基金"></PageIcon>
    </Link> */}
    <Link to='/bank/proxy'>
      <PageIcon src={require('../../assets/cloud/icons8-bank-building-100.png')} title="代存"></PageIcon>
    </Link>
    <Link to='/bank/fixed'>
      <PageIcon src={require('../../assets/cloud/icons8-付款记录-100.png')} title="定期"></PageIcon>
    </Link>
    <Link to='/bank/current'>
      <PageIcon src={require('../../assets/cloud/icons8-硬币-100.png')} title="活期"></PageIcon>
    </Link>
    <Link to='/bank/loan'>
      <PageIcon src={require('../../assets/cloud/icons8-计费-100.png')} title="借贷"></PageIcon>
    </Link>
    <Link to='/bank/withdraw'>
      <PageIcon src={require('../../assets/cloud/icons8-接受现金-100.png')} title="提现"></PageIcon>
    </Link>
    <Link to='/bank/bill'>
      <PageIcon src={require('../../assets/cloud/icons8-账单-100.png')} title="交易记录"></PageIcon>
    </Link>
  </div>
}