import axios from "./request"

const destination = {
  getList({ teamId, name, page, size }) {
    return axios.get("/destination/list", { params: { teamId, name, page, size } })
  },
  getTree(id, teamId) {
    return axios.get("/destination/tree", { params: { id, teamId } })
  },
  getTreeMarkUser(id, userId, teamId) {
    return axios.get("/destination/treeMarkUser", { params: { id, userId, teamId } })
  },
  add({ name, teamId, details, categoryId }) {
    return axios.post("/destination/add", { name, teamId, details, categoryId })
  },
  edit({ id, teamId, name, details, categoryId }) {
    return axios.post("/destination/edit", { id, teamId, name, details, categoryId })
  },
  del(id, teamId) {
    return axios.delete("/destination/delete", { params: { id, teamId } })
  },
  change({ userId, id }) {
    return axios.get("/destination/change", { params: { userId, id } })
  },
}
export default destination