import style from "./Picker.module.scss"
import { useState, useEffect, useRef } from "react"
export default function Picker(props) {
  const [yearList, setYearList] = useState([])
  const [monthList, setMonthList] = useState([])
  const [dayList, setDayList] = useState([])
  const yearRef = useRef(null)
  const monthRef = useRef(null)
  const dayRef = useRef(null)

  function initPickerScroll() {
    let yearArr = [], monthArr = [], dayArr = []
    for (let y = new Date().getFullYear() - 70; y < new Date().getFullYear() + 30; y++) {
      yearArr.push(y)
    }
    setYearList(yearArr)
    for (let m = 1; m < 13; m++) {
      monthArr.push(m)
    }
    setMonthList(monthArr)
    let md = 30
    if (props.month === 2) {
      md = props.year % 4 === 0 ? 29 : 28
    }
    if ([1, 3, 5, 7, 8, , 10, 12].includes(props.month)) {
      md = 31
    }
    for (let d = 1; d <= md; d++) {
      dayArr.push(d)
    }
    setDayList(dayArr)
  }
  function scrollToViewCenterYear(){
    yearRef.current.querySelector(`#y${props.year}`).scrollIntoView({
      block: "center", inline: "center"
    })
  }
  function scrollToViewCenterMonth(){
    monthRef.current.querySelector(`#m${props.month}`).scrollIntoView({
      block: "center", inline: "center"
    })
  }
  function scrollToViewCenterDay(){
    dayRef.current.querySelector(`#d${props.day}`).scrollIntoView({
      block: "center", inline: "center"
    })
  }

  useEffect(()=>{
    initPickerScroll()
    setTimeout(()=>{
      scrollToViewCenterYear()
    },10)
    setTimeout(()=>{
      scrollToViewCenterMonth()
    },20)
    setTimeout(()=>{
      scrollToViewCenterDay()
    },30)
  }, [])

  function monthClick(m) {
    let md, dayArr = []
    if (m === 2) {
      md = props.year % 4 === 0 ? 29 : 28
    } else if ([1, 3, 5, 7, 8, , 10, 12].includes(m)) {
      md = 31
    } else {
      md = 30
    }
    for (let d = 1; d <= md; d++) {
      dayArr.push(d)
    }
    setDayList(dayArr)
    props.setMonth(m)
    if (props.day > md) {
      props.setDay(md)
    }
  }

  return <div className={style.Picker}>
    <div className={`picker ${props.showPicker ? 'active' : ''}`}>
      <div className="picker-content">
        <div className="result">
          <div className="item">{props.year}</div>
          <div className="item">{props.month}</div>
          <div className="item">{props.day}</div>
        </div>
        <div className="scroll">
          <div className="list year" ref={yearRef}>
            {yearList.map(year => <div className="item" id={"y" + year} key={"y" + year} onClick={() => props.setYear(year)}>{year}</div>)}
          </div>
          <div className="list month" ref={monthRef}>
            {monthList.map(month => <div className="item" id={"m" + month} key={"m" + month} onClick={() => monthClick(month)}>{month}</div>)}
          </div>
          <div className="list day" ref={dayRef}>
            {dayList.map(day => <div className="item" id={"d" + day} key={"d" + day} onClick={() => props.setDay(day)}>{day}</div>)}
          </div>
        </div>
        <div className="btns">
          <div className="cancel" onClick={props.cancel}>取消</div>
          <div className="ok" onClick={props.confirm}>确认</div>
        </div>
      </div>
    </div>
  </div>
}