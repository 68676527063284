import "./User.scss"
import { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import Modal from "../../components/Modal/Modal"
import LineInput from "../../components/LineInput/LineInput"
import Toast from "../../components/Toast";
import Progress from "../../components/Progress/Progress"
import Clipper from "../../components/Clipper/Clipper"
import DatePicker from "../../components/DatePicker/DatePicker"

import TeamApi from "../../api/team"
import UserApi from "../../api/user"
import UploadApi from "../../api/upload"

import Context from "../../context"

export default function User() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)
  const [teamBadge, setTeamBadge] = useState(0)
  const [userInfo, setUserInfo] = useState({ props: {} })
  const [showUserInfoModal, setShowUserInfoModal] = useState(false);
  const [baseInfo, setBaseInfo] = useState({});
  const [showAvatarModal, setShowAvatarModal] = useState(false);
  const [file, setFile] = useState(null);
  const [expandProps, setExpandProps] = useState(false);
  const [teamId, setTeamId] = useState("")
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [newTeam, setNewTeam] = useState({ name: "", remark: "" })
  const [showChangePswModal, setShowChangePswModal] = useState(false);
  const [psw, setPsw] = useState({ old: "", new1: "", new2: "" });

  const navigate = useNavigate()

  function getUserInfo(userId) {
    UserApi.getInfo(userId).then(res => {
      setUserInfo(res.data)
      localStorage.setItem("user", JSON.stringify(res.data))
    })
  }

  function calcLevel(value) {
    let step = 500, arr = [];
    let idx = 1, flag = false

    for (let i = 1; i <= 100; i++) {
      let last = arr[arr.length - 1] || 0
      let levelTop = 0
      if (i % 5 === 0) {
        levelTop = last + step * 2
        step = step + Math.floor(last / i / 10) + 500
      } else {
        levelTop = last + step
      }
      arr.push(levelTop)
      if (!flag && value < levelTop) {
        idx = i
        flag = true
      }
    }
    return {
      level: idx - 1, // 等级
      percent: idx === 1 ? value / 500 * 100 : (value - arr[idx - 2]) / arr[idx - 1] * 100 // 百分比
    }
  }

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('user')) || {}
    getUserInfo(user.id)
    if (user.teamId) {
      TeamApi.getNum(user.teamId || JSON.parse(localStorage.getItem('user')).teamId).then(res => {
        setTeamBadge(res.data)
      })
    }
    return () => {
      removeCallback('ok')
    }
  }, [])

  useEffect(() => {
    if (showCreateTeamModal) {
      // 添加tabbar回调
      addCallback(() => { setShowCreateTeamModal(false); removeCallback(); }, () => {
        // 提交数据
        if (!newTeam.name) {
          Toast.error("名称不能为空")
          return
        } else {
          TeamApi.create({ ...newTeam, userId: userInfo.id }).then(res => {
            Toast.success("提交成功")
            if (res.data.name) {
              setShowCreateTeamModal(false);
              removeCallback();
              getUserInfo(userInfo.id)
            }
          })
        }
      })
    }
  }, [showCreateTeamModal, newTeam, userInfo.id])

  useEffect(() => {
    if (!userInfo.team || !userInfo.team.id) {
      addCallback(null, () => {
        if (!teamId) {
          Toast.warn("Team ID 为空, 请输入 Team ID")
          return
        }
        TeamApi.join(userInfo.id, teamId).then(res => {
          getUserInfo(userInfo.id)
          Toast.success("请求发送成功，请等待团队验证通过")
        })
      })
    }
    return () => {
      removeCallback('ok')
    }
  }, [userInfo, teamId])


  function signout() {
    localStorage.removeItem("user")
    navigate("/signin")
  }
  function changePwd() {
    if (psw.new1 !== psw.new2) {
      Toast.error("两次输入密码不一致")
      return
    }
    UserApi.changePsw({ id: userInfo.id, old: psw.old, psw: psw.new1 }).then(() => {
      Toast.success("修改成功，请重新登录", 3000, function () {
        setShowChangePswModal(false);
        removeCallback();
        localStorage.removeItem("user")
        navigate("/signin")
      })
    })
  }
  useEffect(() => {
    if (showChangePswModal) {
      addCallback(() => { setShowChangePswModal(false); removeCallback() }, changePwd)
    }
  }, [showChangePswModal, psw])

  function openBaseChange() {
    setBaseInfo({
      id: userInfo.id,
      nickname: userInfo.nickname,
      motto: userInfo.motto,
      birthday: userInfo.birthday
    })
    setShowUserInfoModal(true)
  }
  useEffect(() => {
    if (showUserInfoModal) {
      addCallback(() => { setShowUserInfoModal(false); removeCallback() }, () => {
        if (baseInfo.motto.length > 20) {
          Toast.warn("箴言需要精简，不能超过20个字")
          return
        }
        UserApi.changeInfo(baseInfo).then(res => {
          getUserInfo(userInfo.id)
          Toast.success('修改成功')
          setShowUserInfoModal(false)
          removeCallback()
        })
      })
    }
  }, [showUserInfoModal, baseInfo])

  function getFile(file) {
    setFile(file)
  }
  useEffect(() => {
    if (file) {
      removeCallback()
    }
    if (showAvatarModal) {
      addCallback(() => { setShowAvatarModal(false); removeCallback() }, () => {
        UploadApi.avatar({ id: userInfo.id, file }).then(res => {
          getUserInfo(userInfo.id)
          Toast.success("修改成功")
          setShowAvatarModal(false);
          removeCallback();
        })
      })
    }
  }, [showAvatarModal, file])


  return <div className="page user">
    <div className="info card">
      <div className="avatar" onClick={() => setShowAvatarModal(true)}>
        <img src={userInfo.avatar || require('../../assets/cute/icons8-google-photos-256.png')} alt="" />
      </div>
      <div className="base-info" onClick={openBaseChange}>
        <div className="name">{userInfo.nickname}</div>
        <div className="motto">{userInfo.motto || <span className="no-motto">这个人很懒，什么都没留下</span>}</div>
      </div>
      <div className={`props ${expandProps ? 'active' : ''}`}>
        <div className="arrow" onClick={() => setExpandProps(!expandProps)}></div>
        <Progress percent={calcLevel(userInfo.props.morality).percent}
          value={'lv. ' + calcLevel(userInfo.props.morality).level}
          color={{ done: '#ff8b8b' }}>品德
        </Progress>
        <Progress percent={calcLevel(userInfo.props.courage).percent}
          value={'lv. ' + calcLevel(userInfo.props.courage).level}
          color={{ done: '#61bfad' }}>勇气
        </Progress>
        <Progress percent={calcLevel(userInfo.props.perseverance).percent}
          value={'lv. ' + calcLevel(userInfo.props.perseverance).level}
          color={{ done: '#32b67a' }}>坚毅
        </Progress>
        <Progress percent={calcLevel(userInfo.props.knowledge).percent}
          value={'lv. ' + calcLevel(userInfo.props.knowledge).level}
          color={{ done: '#f3c9dd' }}>知识
        </Progress>
        <Progress percent={calcLevel(userInfo.props.wisdom).percent}
          value={'lv. ' + calcLevel(userInfo.props.wisdom).level}
          color={{ done: '#f0cf61' }}>智慧
        </Progress>
        <Progress percent={calcLevel(userInfo.props.physique).percent}
          value={'lv. ' + calcLevel(userInfo.props.physique).level}
          color={{ done: '#a6cfe2' }}>体魄
        </Progress>
        <Progress percent={calcLevel(userInfo.props.communicate).percent}
          value={'lv. ' + calcLevel(userInfo.props.communicate).level}
          color={{ done: '#b6cac0' }}>沟通
        </Progress>
        <Progress percent={calcLevel(userInfo.props.creativity).percent}
          value={'lv. ' + calcLevel(userInfo.props.creativity).level}
          color={{ done: '#2c3979' }}>创造
        </Progress>
        <Progress percent={calcLevel(userInfo.props.implement).percent}
          value={'lv. ' + calcLevel(userInfo.props.implement).level}
          color={{ done: '#77eedf' }}>执行
        </Progress>
      </div>
    </div>
    <div className="card team">
      {!userInfo.teamId ?
        <div className="no-team">
          <div className="create-team" onClick={() => setShowCreateTeamModal(true)}>创建团队</div>
          <div className="v-line"></div>
          <div className="join-team">
            <LineInput label="加入团队:" placeholder="请输入团队ID" value={teamId} onChange={(e) => setTeamId(e.target.value)}></LineInput>
          </div>
        </div> :
        <div className="team-name" onClick={() => navigate("/user/team")}>
          {userInfo.isGuider && teamBadge ? <div className="badge">{teamBadge}</div> : ''}
          团队: <span>{userInfo.team.name}</span>
        </div>
      }
    </div>
    <div className="card" onClick={() => setShowChangePswModal(true)}>
      修改密码
    </div>
    <div className="card" onClick={signout}>
      退出登录
    </div>

    <Modal show={showCreateTeamModal} title="创建团队" tips={{ ok: '创建', cancel: '返回' }}>
      <LineInput label="团队名称" value={newTeam.name} onChange={(e) => setNewTeam({ ...newTeam, name: e.target.value })}></LineInput>
      <LineInput label="团队简介" value={newTeam.remark} onChange={(e) => setNewTeam({ ...newTeam, remark: e.target.value })}></LineInput>
    </Modal>
    <Modal show={showChangePswModal} title="修改密码" tips={{ ok: '修改', cancel: '返回' }}>
      <LineInput label="原密码" value={psw.old} type="password" placeholder="请输入旧密码" onChange={(e) => setPsw({ ...psw, old: e.target.value })}></LineInput>
      <LineInput label="新密码" value={psw.new1} type="password" placeholder="请输入新密码" onChange={(e) => setPsw({ ...psw, new1: e.target.value })}></LineInput>
      <LineInput label="重复新密码" value={psw.new2} type="password" placeholder="请重复新密码" onChange={(e) => setPsw({ ...psw, new2: e.target.value })} onSubmit={changePwd}></LineInput>
    </Modal>
    <Modal show={showUserInfoModal} title="修改基本信息" tips={{ ok: '修改', cancel: '返回' }}>
      <div className="base-change">
        <LineInput label="昵称" value={baseInfo.nickname} placeholder="请输入新昵称" onChange={(e) => setBaseInfo({ ...baseInfo, nickname: e.target.value })}></LineInput>
        <LineInput label="箴言" value={baseInfo.motto} placeholder="请输入一句话勉励自己" onChange={(e) => setBaseInfo({ ...baseInfo, motto: e.target.value })}></LineInput>
        {
          baseInfo.birthday ?
            <div>
              {baseInfo.birthday}
              <p>不可修改，请联系团队引导者修改！</p>
            </div> :
            <div>
              <DatePicker label="生日" styleType="line" date={baseInfo.birthday} onChange={date => setBaseInfo({ ...baseInfo, birthday: date?.string })}></DatePicker>
              <p>注意：生日添加后不可修改！</p>
            </div>
        }

      </div>
    </Modal>
    <Modal show={showAvatarModal} title="修改头像" tips={{ ok: '确认', cancel: '返回' }}>
      <Clipper getFile={getFile}></Clipper>
    </Modal>
  </div>
}