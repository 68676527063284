import "./Check.scss"
import { useState, useEffect, useContext, useMemo } from "react"
import TitleBar from "../../components/TitleBar/TitleBar"
import Modal from "../../components/Modal/Modal"
import Toast from "../../components/Toast"
import bankUtils from "../../utils/bank"

import { currentWithdraw } from "../../config/current"

import CheckApi from "../../api/check"

import Context from "../../context"

let achieveModalTitle = { "10": "审核成就", "11": "查看成就", '20': "审核提现", "21": "查看提现", '30': "审核借贷", "31": "查看借贷" }
export default function Check() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)

  const [sortDesc, setSortDesc] = useState(true)
  const [badge, setBadge] = useState(0)
  const [user, setUser] = useState({})
  const [search, setSearch] = useState({ type: '1', status: '0', sort: 'desc' })
  const [list, setList] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [checkInfo, setCheckInfo] = useState({})

  const searchString = useMemo(() => search.type + search.status)

  function getNum() {
    CheckApi.getNum({
      userId: user.id || JSON.parse(localStorage.getItem('user')).id,
      teamId: user.teamId || JSON.parse(localStorage.getItem('user')).teamId
    }).then(res => {
      setBadge(res.data)
    })
  }
  function getList() {
    CheckApi.getList({
      teamId: user.teamId || JSON.parse(localStorage.getItem('user')).teamId,
      ...search
    }).then(res => {
      setList(res.data)
    })
  }
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))
    getNum()
    getList()
  }, [])

  function typeChange(type) {
    setList([])
    setSearch({ ...search, type })
  }
  function statusChange(status) {
    setSearch({ ...search, status })
  }
  function changeSort(sort) {
    setSortDesc(sort)
    setSearch({ ...search, sort: sort ? 'desc' : 'asc' })
  }
  useEffect(() => {
    getNum()
    getList()
  }, [search.sort, search.type, search.status])

  function itemClick(item) {
    setCheckInfo(item)
    if(search.type==='4' && search.status==='0'){
      if(item.operatorInfo.id!==user.id){
        setShowModal(true)
      }else{
        Toast.error("不能审核自己发起的代存操作")
      }
      return
    }
    setShowModal(true)
  }
  useEffect(() => {
    if (!showModal) {
      return
    }
    if (searchString === '10') {
      if (checkInfo.status === '0') {
        setCheckInfo({ ...checkInfo, status: '1' })
      }
      // 如果状态不是待审，则消除重复添加导航事件的影响
      if (checkInfo.status !== '0') {
        removeCallback()
      }
      addCallback(() => { setShowModal(false); removeCallback() }, () => {
        if (checkInfo.status === '0') {
          Toast.error("未选择是否通过")
          return
        }
        CheckApi.checkAchieve({
          id: checkInfo.id,
          status: checkInfo.status
        }).then(res => {
          getNum()
          getList()
          Toast.success("审核成功")
          setShowModal(false)
          removeCallback()
        })
      })
    } else if (searchString === '20') {
      if (checkInfo.status === '0') {
        setCheckInfo({ ...checkInfo, status: '1' })
      }
      // 如果状态不是待审，则消除重复添加导航事件的影响
      if (checkInfo.status !== '0') {
        removeCallback()
      }
      addCallback(() => { setShowModal(false); removeCallback() }, () => {
        if (checkInfo.status === '0') {
          Toast.error("未选择是否通过")
          return
        }
        CheckApi.checkWithdraw({
          id: checkInfo.id,
          status: checkInfo.status
        }).then(() => {
          getNum()
          getList()
          Toast.success("审核成功")
          setShowModal(false)
          removeCallback()
        })
      })
    } else if (searchString === '30') {
      if (checkInfo.status === '0') {
        setCheckInfo({ ...checkInfo, status: '1' })
      }
      // 如果状态不是待审，则消除重复添加导航事件的影响
      if (checkInfo.status !== '0') {
        removeCallback()
      }
      addCallback(()=>{setShowModal(false); removeCallback()}, ()=>{
        if (checkInfo.status === '0') {
          Toast.error("未选择是否通过")
          return
        }
        CheckApi.checkLoan({
          id: checkInfo.id,
          status: checkInfo.status
        }).then(() => {
          getNum()
          getList()
          Toast.success("审核成功")
          setShowModal(false)
          removeCallback()
        })
      })
    } else if (searchString === '40') {
      if (checkInfo.status === '0') {
        setCheckInfo({ ...checkInfo, status: '1' })
      }
      // 如果状态不是待审，则消除重复添加导航事件的影响
      if (checkInfo.status !== '0') {
        removeCallback()
      }
      addCallback(()=>{setShowModal(false); removeCallback()}, ()=>{
        if (checkInfo.status === '0') {
          Toast.error("未选择是否通过")
          return
        }
        CheckApi.checkProxy({
          id: checkInfo.id,
          status: checkInfo.status,
          checker: user.id
        }).then(() => {
          getNum()
          getList()
          Toast.success("审核成功")
          setShowModal(false)
          removeCallback()
        })
      })
    } else {
      addCallback(() => { setShowModal(false); removeCallback() }, () => {
        setShowModal(false); removeCallback()
      })
    }
  }, [showModal, checkInfo.status])

  function setStatus(status) {
    setCheckInfo({ ...checkInfo, status })
  }

  function statusIconJSX(status){
    return {
      "0": "",
      "1": <img src={require("../../assets/cute/icons8-check-circle-128.png")} alt="" />,
      "2": <img src={require("../../assets/cute/icons8-cancel-64.png")} alt="" />,
    }[status]
  }

  return <div className="page check">
    <div className="search card">
      <div className="types">
        <div className="label">类别：</div>
        <div className={`item ${search.type === '1' ? 'active' : ''}`} onClick={() => typeChange('1')}>
          成就
          {badge.achieve ? <span className="badge">{badge.achieve}</span> :""}
        </div>
        <div className={`item ${search.type === '2' ? 'active' : ''}`} onClick={() => typeChange('2')}>
          提现
          {badge.withdraw ? <span className="badge">{badge.withdraw}</span> :""}
        </div>
        <div className={`item ${search.type === '3' ? 'active' : ''}`} onClick={() => typeChange('3')}>
          借贷
          {badge.loan ? <span className="badge">{badge.loan}</span> :""}
        </div>
        <div className={`item ${search.type === '4' ? 'active' : ''}`} onClick={() => typeChange('4')}>
          代存
          {badge.proxy ? <span className="badge">{badge.proxy}</span> :""}
        </div>
      </div>
      <div className="status">
        <div className="label">状态：</div>
        <div className={`item ${search.status === '0' ? 'active' : ''}`} onClick={() => statusChange('0')}>待审核</div>
        <div className={`item ${search.status === '1' ? 'active' : ''}`} onClick={() => statusChange('1')}>已审核</div>
      </div>
    </div>
    <TitleBar title="信息审核" sort={sortDesc} changeSort={changeSort}></TitleBar>
    <div className="list">
      {list && list.length ? list.map(item => <div key={item.id} className={`card ${item.status === '2' ? 'gray' : ''}`} onClick={() => itemClick(item)}>
        {
          search.type === '1' && <div className="achieve">
            <div className="line">
              <div className="label">申请人</div>
              <div className="info">{item.user.nickname}</div>
            </div>
            <div className="line">
              <div className="label">成就名称</div>
              <div className="info" style={{ background: item.achievement && item.achievement.color }}>{item.achievement && item.achievement.name}</div>
            </div>
            <div className="line">
              <div className="label">创建时间</div>
              <div className="info">{item.createdAt}</div>
            </div>
            <div className="stutas">
              {statusIconJSX(item.status)}
            </div>
          </div>
        }
        {
          search.type === '2' && <div className="withdraw">
            <div className="line">
              <div className="value">{item.user.nickname}</div>
              <div className="label">申请人</div>
            </div>
            <div className="line">
              <div className="value">§{bankUtils.moneyFormat(item.amount)}</div>
              <div className="label">提现金额</div>
            </div>
            <div className="line">
              <div className="value">{currentWithdraw[item.type]}</div>
              <div className="label">到账方式</div>
            </div>
            <div className="stutas">
              {statusIconJSX(item.status)}
            </div>
          </div>
        }
        {
          search.type === '3' && <div className="loan">
            <div className="line">
              <div className="label">申请人</div>
              <div className="value">{item.user.nickname}</div>
            </div>
            <div className="line">
              <div className="label">借贷金额</div>
              <div className="value amount">§{bankUtils.moneyFormat(item.amount)}</div>
            </div>
            <div className="stutas">
              {statusIconJSX(item.status)}
            </div>
          </div>
        }
        {
          search.type === '4' && <div className="loan">
            <div className="line">
              <div className="label">操作人</div>
              <div className="value">{item.operatorInfo.nickname}</div>
            </div>
            <div className="line">
              <div className="label">目标用户</div>
              <div className="value">{item.userInfo.nickname}</div>
            </div>
            <div className="line">
              <div className="label">代存金额</div>
              <div className="value amount">§{bankUtils.moneyFormat(item.amount)}</div>
            </div>
            <div className="stutas">
              {statusIconJSX(item.status)}
            </div>
          </div>
        }
      </div>) : <div style={{ color: 'var(--gray-300)', textAlign: 'center' }}>暂无数据</div>}
    </div>

    <Modal show={showModal} title={achieveModalTitle[searchString]} tips={{ ok: searchString === '10' || searchString === '20' || searchString === '30' || searchString === '40' ? '确认' : '返回', cancel: '返回' }}>
      <div className="check-info">
        {search.type === '1' && <div className="achieve">
          <div className="line">
            <div className="label">申请人</div>
            <div className="info">{checkInfo.user && checkInfo.user.nickname}</div>
          </div>
          <div className="line">
            <div className="label">名称</div>
            <div className="info">{checkInfo.achievement && checkInfo.achievement.name}</div>
          </div>
          <div className="line">
            <div className="label">备注</div>
            <div className="info">{(checkInfo.achievement && checkInfo.achievement.remark) || '无'}</div>
          </div>
          <div className="line">
            <div className="label">评级</div>
            <div className="info">{checkInfo.achievement && <div style={{ width: '100%', height: '20px', background: checkInfo.achievement.color }}></div>}</div>
          </div>
          {checkInfo.achievement && checkInfo.achievement.morality ? <div className="line">
            <div className="label">品德</div>
            <div className="info"> {checkInfo.achievement.morality}</div>
          </div> : ''}
          {checkInfo.achievement && checkInfo.achievement.courage ? <div className="line">
            <div className="label">勇气</div>
            <div className="info"> {checkInfo.achievement.courage}</div>
          </div> : ''}
          {checkInfo.achievement && checkInfo.achievement.perseverance ? <div className="line">
            <div className="label">坚毅</div>
            <div className="info"> {checkInfo.achievement.perseverance}</div>
          </div> : ''}
          {checkInfo.achievement && checkInfo.achievement.knowledge ? <div className="line">
            <div className="label">知识</div>
            <div className="info"> {checkInfo.achievement.knowledge}</div>
          </div> : ''}
          {checkInfo.achievement && checkInfo.achievement.wisdom ? <div className="line">
            <div className="label">智慧</div>
            <div className="info"> {checkInfo.achievement.wisdom}</div>
          </div> : ''}
          {checkInfo.achievement && checkInfo.achievement.physique ? <div className="line">
            <div className="label">体魄</div>
            <div className="info"> {checkInfo.achievement.physique}</div>
          </div> : ''}
          {checkInfo.achievement && checkInfo.achievement.communicate ? <div className="line">
            <div className="label">沟通</div>
            <div className="info"> {checkInfo.achievement.communicate}</div>
          </div> : ''}
          {checkInfo.achievement && checkInfo.achievement.creativity ? <div className="line">
            <div className="label">创造</div>
            <div className="info"> {checkInfo.achievement.creativity}</div>
          </div> : ''}
          {checkInfo.achievement && checkInfo.achievement.implement ? <div className="line">
            <div className="label">执行</div>
            <div className="info"> {checkInfo.achievement.implement}</div>
          </div> : ''}
          <div className="line">
            <div className="label">金币</div>
            <div className="info">{checkInfo.achievement && checkInfo.achievement.coin}</div>
          </div>
          <div className="line">
            <div className="label">创建时间</div>
            <div className="info">{checkInfo.achievement && checkInfo.achievement.createdAt}</div>
          </div>
          <div className="line">
            <div className="label">更新时间</div>
            <div className="info">{checkInfo.achievement && checkInfo.achievement.updatedAt}</div>
          </div>
        </div>}
        {search.type === '2' && <div className="withdraw">
          <div className="line">
            <div className="label">申请人</div>
            <div className="value">{checkInfo.user && checkInfo.user.nickname}</div>
          </div>
          <div className="line">
            <div className="label">提现金额</div>
            <div className="value">§{bankUtils.moneyFormat(checkInfo.amount)} </div>
          </div>
          <div className="line">
            <div className="label">兑换人民币</div>
            <div className="value">￥{bankUtils.moneyFormat(checkInfo.amount / 100)}</div>
          </div>
          <div className="line">
            <div className="label">到账方式</div>
            <div className="value">{currentWithdraw[checkInfo.type]}</div>
          </div>
          <div className="line">
            <div className="label">备注</div>
            <div className="value">{checkInfo.remark || '无'}</div>
          </div>
        </div>}
        {search.type === '3' && <div className="loan">
          <div className="line">
            <div className="label">申请人</div>
            <div className="value">{checkInfo.user && checkInfo.user.nickname}</div>
          </div>
          <div className="line">
            <div className="label">借款金额</div>
            <div className="value">§{bankUtils.moneyFormat(checkInfo.amount)} </div>
          </div>
          <div className="line">
            <div className="label">实际到账金额</div>
            <div className="value">§{bankUtils.moneyFormat(checkInfo.amount-checkInfo.charge)}</div>
          </div>
          <div className="line">
            <div className="label">应还总额</div>
            <div className="value">§{bankUtils.moneyFormat(checkInfo.repay)}</div>
          </div>
          <div className="line">
            <div className="label">每期应还</div>
            <div className="value">§{bankUtils.moneyFormat(checkInfo.singleAmount)}</div>
          </div>
          <div className="line">
            <div className="label">期数</div>
            <div className="value">{checkInfo.period} 期</div>
          </div>
          <div className="line">
            <div className="label">利率</div>
            <div className="value">{checkInfo.rate}%</div>
          </div>
          <div className="line">
            <div className="label">利息</div>
            <div className="value">§{bankUtils.moneyFormat(checkInfo.interest)}</div>
          </div>
          <div className="line">
            <div className="label">手续费</div>
            <div className="value">§{bankUtils.moneyFormat(checkInfo.charge)}</div>
          </div>
          <div className="line">
            <div className="label">备注</div>
            <div className="value">{checkInfo.remark || '无'}</div>
          </div>
        </div>}
        {search.type === '4' && <div className="withdraw">
          <div className="line">
            <div className="label">操作人</div>
            <div className="value">{checkInfo.operatorInfo && checkInfo.operatorInfo.nickname}</div>
          </div>
          <div className="line">
            <div className="label">目标用户</div>
            <div className="value">{checkInfo.userInfo && checkInfo.userInfo.nickname}</div>
          </div>
          <div className="line">
            <div className="label">代存金额</div>
            <div className="value">§{bankUtils.moneyFormat(checkInfo.amount)} </div>
          </div>
          <div className="line">
            <div className="label">人民币价值</div>
            <div className="value">￥{bankUtils.moneyFormat(checkInfo.amount / 100)}</div>
          </div>
          <div className="line">
            <div className="label">到账方式</div>
            <div className="value">活期账户</div>
          </div>
          <div className="line">
            <div className="label">备注</div>
            <div className="value">{checkInfo.remark || '无'}</div>
          </div>
        </div>}
        {
          searchString === '10' || searchString === '20' || searchString === '30' || searchString === '40' ? <div className="operate">
            <div className={`item ok ${checkInfo.status === '1' ? 'active' : ''}`} onClick={() => setStatus('1')}>通过</div>
            <div className={`item no ${checkInfo.status === '2' ? 'active' : ''}`} onClick={() => setStatus('2')}>不通过</div>
          </div> : ''
        }
        {
          searchString === '11' || searchString === '21' || searchString === '31' || searchString === '41' ? <div className={`status-line ${checkInfo.status==='1'?'pass':'nopass'}`}>
            {checkInfo.status ==="1"? "审核通过": "审核未通过"}
          </div> : ''
        }
      </div>
    </Modal>
  </div >
}