
import { useContext, useEffect, useState } from "react"
import "./Current.scss"
import TitleBar from "../../../components/TitleBar/TitleBar"
import Modal from "../../../components/Modal/Modal"
import DatePicker from "../../../components/DatePicker/DatePicker"
import LineInput from "../../../components/LineInput/LineInput"
import Toast from "../../../components/Toast"

import BankApi from "../../../api/bank"
import WalletApi from "../../../api/wallet"

import { currentType } from "../../../config/current"
import bankUtils from "../../../utils/bank"

import Context from "../../../context"

export default function Current() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)
  const [user, setUser] = useState({ id: '' })
  const [total, setTotal] = useState('')
  const [wallet, setWallet] = useState("")
  const [rate, setRate] = useState("")
  const [currentList, setCurrentList] = useState([])
  const [sortDesc, setSortDesc] = useState(true)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showOutModal, setShowOutModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [searchDate, setSearchDate] = useState({
    start: new Date().toLocaleDateString(),
    end: new Date().toLocaleDateString()
  })
  const [currentInfo, setCurrentInfo] = useState({ id: '', amount: '', remark: '' })

  function getWallet() {
    WalletApi.getUserCoin(user.id || JSON.parse(localStorage.getItem('user')).id).then(res => {
      setWallet(res.data)
    })
  }

  function getCurrent() {
    BankApi.getCurrent(user.id || JSON.parse(localStorage.getItem('user')).id).then(res => {
      setTotal(res.data)
      setRate(res.rate)
      getCurrentList()
    })
  }
  function getCurrentList() {
    console.log(searchDate);
    BankApi.getCurrentList({
      id: user.id || JSON.parse(localStorage.getItem('user')).id,
      start: searchDate.start,
      end: searchDate.end,
      sort: sortDesc ? 'desc' : 'asc',
      page: 1,
    }).then(res => {
      setCurrentList([...res.data])
    })
  }

  function changeSort() {
    setSortDesc(!sortDesc)
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))
    getWallet()
    getCurrent()
    setCurrentInfo({ ...currentInfo, id: JSON.parse(localStorage.getItem('user')).id })
  }, [])

  function showAdd() {
    setShowAddModal(true);
    setCurrentInfo({ id: user.id || JSON.parse(localStorage.getItem('user')).id, amount: '', remark: '' })
  }
  function showOut() {
    setCurrentInfo({ id: user.id || JSON.parse(localStorage.getItem('user')).id, amount: '', remark: '' })
    setShowOutModal(true);
  }

  function showProtocol() {
    setShowInfoModal(true);
    addCallback(() => {
      setShowInfoModal(false);
      removeCallback("back");
    }, () => {
      // 提交数据
      setShowInfoModal(false);
      removeCallback("ok");
    })
  }

  useEffect(() => {
    getCurrentList()
  }, [sortDesc, searchDate])


  useEffect(() => {
    if (showAddModal) {
      if (currentInfo.amount) {
        removeCallback()
      }
      addCallback(() => { setShowAddModal(false); removeCallback() }, () => {
        if (currentInfo.amount > wallet) {
          Toast.error('超过可存款金额')
          return
        }
        BankApi.saveCurrent({ ...currentInfo }).then(res => {
          Toast.success('存款成功')
          getWallet()
          getCurrent()
          setShowAddModal(false);
          removeCallback();
        })
      })
    }
  }, [showAddModal, currentInfo])

  useEffect(() => {
    if (showOutModal) {
      if (currentInfo.amount) {
        removeCallback()
      }
      addCallback(() => { setShowOutModal(false); removeCallback() }, () => {
        if (currentInfo.amount > total) {
          Toast.error('超过可取款金额')
          return
        }
        BankApi.outCurrent({ ...currentInfo }).then(res => {
          Toast.success('取款成功')
          getWallet()
          getCurrent()
          setShowOutModal(false);
          removeCallback();
        })
      })
    }
  }, [showOutModal, currentInfo])

  return <div className="page current">
    <div className="card current-info">
      <div className="data">
        <div className="value total">§{bankUtils.moneyFormat(total)}</div>
        <div className="label">当前余额</div>
        <div className="value rate">{rate}%</div>
        <div className="label">当前年利率</div>
      </div>

      <div className="btn-group">
        <div className="btn add" onClick={showAdd}>存款</div>
        <div className="btn out" onClick={showOut}>取款</div>
        <div className="btn info" onClick={showProtocol}>活期规则</div>
      </div>
    </div>
    <div className="search-date">
      <DatePicker date={searchDate.start} onChange={date => setSearchDate({ ...searchDate, start: date.string })}></DatePicker>
      -
      <DatePicker date={searchDate.end} onChange={date => setSearchDate({ ...searchDate, end: date.string })}></DatePicker>
    </div>
    <TitleBar title="活期存款" sort={sortDesc} changeSort={changeSort}></TitleBar>
    <div className="current-list">
      {
        currentList.map(item => <div className="item" key={item.id}>
          <div className="title">
            <div className="total">金额：<span>§{bankUtils.moneyFormat(item.amount) || 0}</span></div>
            <div className="type"><span style={{ color: currentType[item.type].color }}>{currentType[item.type].name}</span></div>
          </div>
          <div className="info">
            <div className="block">
              <div className="label">余额</div>
              <div className="value">§{bankUtils.moneyFormat(item.currentTotal)}</div>
            </div>
            <div className="block">
              <div className="label">手续费</div>
              <div className="value">§{bankUtils.moneyFormat(item.charge) || 0}</div>
            </div>
            <div className="block">
              <div className="label">操作时间</div>
              <div className="value">{item.createdAt.slice(0, 10)}</div>
            </div>
          </div>
        </div>)
      }
    </div>

    <Modal show={showOutModal} title="活期取款" tips={{ cancel: '返回', ok: '取款' }}>
      <div className="out">
        <LineInput label={`金额 § ${bankUtils.getUnit(currentInfo.amount)}`} value={currentInfo.amount} type='number' placeholder="请输入取款金额" onChange={(e) => setCurrentInfo({ ...currentInfo, amount: e.target.value })}></LineInput>
        <LineInput label="备注" value={currentInfo.remark} placeholder="请输入取款备注" onChange={(e) => setCurrentInfo({ ...currentInfo, remark: e.target.value })}></LineInput>
      </div>
    </Modal>
    <Modal show={showAddModal} title="活期存款" tips={{ cancel: '返回', ok: '存款' }}>
      <div className="save">
        <LineInput label={`金额 § ${bankUtils.getUnit(currentInfo.amount)}`} value={currentInfo.amount} type='number' placeholder="请输入存款金额" onChange={(e) => setCurrentInfo({ ...currentInfo, amount: e.target.value })}></LineInput>
        <div className="tips">* 可存款金额：<span>§ {bankUtils.moneyFormat(wallet)}</span></div>
        <LineInput label="备注" value={currentInfo.remark} placeholder="请输入存款备注" onChange={(e) => setCurrentInfo({ ...currentInfo, remark: e.target.value })}></LineInput>
      </div>
    </Modal>
    <Modal show={showInfoModal} title="活期存款协议及相关说明" tips={{ cancel: '返回', ok: '返回' }}>
      <div className="protocol">
        <h1>活期存取款说明</h1>
        <h2>一、定义</h2>
        <p>银行：本系统金币池银行，以下简称银行</p>
        <p>用户：发起存款方，以下简称您</p>
        <h2>二、协议确认与补充、修改</h2>
        <p>本协议包括协议正文及银行已发布的或将来可能发布的各类规则（含业务规范、通知、公告等）。所有上述各类规则等为本协议不可分割的组成部分，与本协议正文具有同等法律效力。如上述各类规则等与本协议有冲突，则以后发布者为准。您可以在当前页面中查阅相关协议条款。银行根据业务发展对本协议内容进行变更的，将通过本页面发布，不再逐一通知每位用户。如果您不接受该变更，有权不适用此服务；如果在上述变更发布后，您继续使用服务，视为您接受该变更。</p>
        <h2>三、服务及交易规则</h2>
        <p>3.1 使用本服务则表示您接受后续规则。</p>
        <p>3.2 存取款不需要审核，交易因数据原因中出现问题的，需联系银行找回数据，如数据无法找回的，按历史数据推断补偿，补偿方式最终解释权归银行所有。</p>
        <p>3.3 活期存款每季度结息一次，结息时间为每季度第一个月第二日，结息利率以结息当天活期利率为准，结息计算方式为，利息=结息时账户金额×结息时利率×当前季度天数÷366。</p>
        <p>3.4 活期利率每日更新。更新方式为，在上一日利率基础上随机浮动0-20个基点，一基点为0.00001</p>
        <p>3.5 其余未尽事项，以银行解释为准。</p>
        <h2>四、其他</h2>
        <p>4.1 银行已提请您特别注意有关免除、限制银行责任，和银行单方面拥有某些权利以及增加您义务或免除、限制您权利的条款，并已应您的要求对上述条款予以充分解释说明，各方对本协议条款的理解完全一致，并自愿接受约束。</p>
        <p>4.2 若您与银行之间发生争议，由双方协商解决。协商不成的，就算了，毕竟银行钱包都是我开的。</p>
        <p>4.3 本协议的成立、生效、履行、解释及纠纷解决，不适用于法律，仅适用金融惯例。</p>
        <p>4.4 本协议条款无论因何种原因部分无效或不可执行，都不影响本协议其他条款的效力。</p>
        <p>4.5 本协议中未尽事宜，按照金融惯例办理。</p>
        <p>4.6 本协议中出现的金融管理一词的解释权归银行所有。</p>
      </div>
    </Modal>
  </div>
}