import { useEffect, useRef } from "react"
import style from "./LineTextarea.module.scss"

export default function LineTextarea(props){
  const formRef = useRef(null)
  useEffect(()=>{
    formRef.current.onsubmit = function(e){
      e.preventDefault()
      props.onSubmit && props.onSubmit()
    };
  },[formRef])
  return <div className={style.LineTextarea}>
    <div className={`line-input ${props.align || 'vertical'}`}> {/**horizontal vertical */}
      <div className="li-label">
        {props.require?<span className="star">*</span>:''}
        {props.label}</div>
      <form ref={formRef} action=""><textarea type={props.type || 'text'} placeholder={props.placeholder} value={props.value} onChange={props.onChange}></textarea></form>
    </div>
  </div>
}