import { HashRouter, Routes, Route  } from "react-router-dom"
import Layout from "./Layout/Layout"
import Home from "./pages/Home/Home"
import Achievement from "./pages/Achievement/Achievement"
import Punish from "./pages/Punish/Punish"
import TravelIndex from "./pages/Travel/index"
import Travel from "./pages/Travel/Travel"
import Random from "./pages/Travel/Random/Random"
import Plan from "./pages/Travel/Plan/Plan"
import List from "./pages/List/List"
import UserIndex from "./pages/User/UserIndex"
import User from "./pages/User/User"
import Team from "./pages/User/Team/Team"
import Wallet from "./pages/Wallet/Wallet"
import Store from "./pages/Store/Store"
import BankIndex from "./pages/Bank/index"
import Bank from "./pages/Bank/Bank"
import Fixed from "./pages/Bank/Fixed/Fixed"
import Current from "./pages/Bank/Current/Current"
import Withdraw from "./pages/Bank/Withdraw/Withdraw"
import Bill from "./pages/Bank/Bill/Bill"
import Loan from "./pages/Bank/Loan/Loan"
import Proxy from "./pages/Bank/Proxy/Proxy"
import Check from "./pages/Check/Check"
import ManageIndex from "./pages/Manage"
import Manage from "./pages/Manage/Manage"
import AchieveCategory from "./pages/Manage/AchieveCategory/AchieveCategory"
import Achieve from "./pages/Manage/Achieve/Achieve"
import Depot from "./pages/Manage/Depot/Depot"
import Destination from "./pages/Manage/Destination/Destination"
import DestinationCategory from "./pages/Manage/DestinationCategory/DestinationCategory"

import Signin from "./pages/Signin/Signin"
import Signup from "./pages/Signup/Signup"
import Forget from "./pages/Forget/Forget"
import Page404 from "./pages/Page404/Page404"

import Context from "./context"

function App() {
  console.log(process.env.NODE_ENV)
  return (
    <Context.MyContext>
      <div className="App">
        <HashRouter>
          <Routes>
            <Route path="/" element={<Layout/>} >
              <Route index element={<Home />} />
              <Route path="/achievement" element={<Achievement />} />
              <Route path="/punish" element={<Punish />} />
              <Route path="/bank" element={<BankIndex />}>
                <Route index element={<Bank />} />
                <Route path="proxy" element={<Proxy />} />
                <Route path="loan" element={<Loan />} />
                <Route path="fixed" element={<Fixed />} />
                <Route path="current" element={<Current />} />
                <Route path="withdraw" element={<Withdraw />} />
                <Route path="bill" element={<Bill />} />
              </Route>
              <Route path="/List" element={<List />} />
              <Route path="/travel" element={<TravelIndex />}>
                <Route index element={<Travel />} />
                <Route path="random" element={<Random />} />
                <Route path="plan" element={<Plan />} />
              </Route>
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/store" element={<Store />} />
              <Route path="/user" element={<UserIndex />} >
                <Route index element={<User />} ></Route>
                <Route path="team" element={<Team />} ></Route>
              </Route>
              <Route path="/check" element={<Check />} />
              <Route path="/manage" element={<ManageIndex />}>
                <Route index element={<Manage />} />
                <Route path="achievecategory" element={<AchieveCategory />} />
                <Route path="achieve" element={<Achieve />} />
                <Route path="depot" element={<Depot />} />
                <Route path="destination" element={<Destination />} />
                <Route path="destination-category" element={<DestinationCategory />} />
              </Route>
            </Route>
            
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forget" element={<Forget />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </HashRouter>
        
        <HashRouter>
          <Routes>
          </Routes>
        </HashRouter>
      </div>
    </Context.MyContext>
  );
}



export default App;
