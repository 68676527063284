import "./Punish.scss"
import { useEffect, useState, useContext } from "react"
import Context from "../../context"
import TitleBar from "../../components/TitleBar/TitleBar"
import Modal from "../../components/Modal/Modal"
import LineInput from "../../components/LineInput/LineInput"
import Toast from "../../components/Toast"

import teamApi from "../../api/team"
import punishApi from "../../api/punish"


export default function Punish() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)

  // get user data
  const [user, setUser] = useState({})
  const [team, setTeam] = useState({ users: [] })
  function getTeam() {
    teamApi.getInfo(user.teamId || JSON.parse(localStorage.getItem("user")).teamId).then(res => {
      setTeam(res.data)
      setPunishInfo({ ...punishInfo, teamId: res.data.id })
    })
  }

  const [filter, setFilter] = useState({
    userId: "",
    type: "1",
    pageNo: 1,
    pageSize: 50
  })
  const [list, setList] = useState([])
  function getList() {
    punishApi.getList(filter).then(res => {
      setList(res.data)
    })
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")))
    setFilter({ ...filter, userId: JSON.parse(localStorage.getItem("user")).id })
    getTeam()
  }, [])

  useEffect(() => {
    getList()
  }, [filter])

  // add punish
  const [showAddModal, setShowAddModal] = useState(false)
  const [punishInfo, setPunishInfo] = useState({
    userId: "",
    teamId: "",
    creator: "",
    name: "",
    remark: "",
  })
  const [punishVal, setPunishVal] = useState({
    morality: "",
    courage: "",
    perseverance: "",
    knowledge: "",
    wisdom: "",
    physique: "",
    communicate: "",
    creativity: "",
    implement: "",
    coin: "",
  })
  function toAdd() {
    setShowAddModal(true)
  }
  function userClick(user) {
    setPunishInfo({ ...punishInfo, userId: user.id, creator: user.id })
  }

  useEffect(() => {
    if (showAddModal) {
      if (punishInfo.name) {
        removeCallback()
      }
      addCallback(() => { setShowAddModal(false); removeCallback() }, () => {
        if (!punishInfo.userId) {
          Toast.error("未选择惩罚对象")
          return
        }
        if (!punishInfo.name) {
          Toast.error("未输入惩罚原因")
          return
        }
        if (Object.values(punishVal).every(v => v === 0)) {
          Toast.error("未设置惩罚项")
          return
        }
        Object.keys(punishVal).forEach(key => {
          punishVal[key] = -Number(punishVal[key]) || 0
        })
        punishApi.add({ ...punishInfo, ...punishVal }).then(res => {
          showAddModal(false);
          removeCallback();
          Toast.success("添加成功，等待审核")
          getList()
        })
      })
    }
  }, [showAddModal, punishInfo, punishVal])

  // 查看审核
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [checkType, setCheckType] = useState("1")
  const [editInfo, setEditInfo] = useState({
    userId: "",
    userInfo: {},
    teamId: "",
    creator: "",
    creatorInfo: {},
    checker: "",
    checkerInfo: {},
    name: "",
    remark: "",
    morality: "",
    courage: "",
    perseverance: "",
    knowledge: "",
    wisdom: "",
    physique: "",
    communicate: "",
    creativity: "",
    implement: "",
    coin: "",
  })
  function openViewEditModal(data) {
    setEditInfo(data)
    setShowDetailModal(true)
  }
  useEffect(() => {
    if (showDetailModal) {
      if (punishInfo.name) {
        removeCallback()
      }
      addCallback(() => { setShowDetailModal(false); removeCallback(); }, () => {
        if (filter.type === '1') {
          setShowDetailModal(false);
          removeCallback();
        } else {
          punishApi.check({ id: editInfo.id, userId: user.id, status: checkType }).then(res => {
            setShowDetailModal(false);
            removeCallback();
            getList()
          })
        }
      })
    }
  }, [showDetailModal, filter, checkType])


  return <div className="page punish">
    <div className="card add" onClick={toAdd}><img alt="" src={require("../../assets/cute/icons8-plus.gif")}></img></div>
    <div className="cate">
      <div className={`type ${filter.type === '1' ? 'active' : ''}`} onClick={() => setFilter({ ...filter, type: "1" })}>惩罚列表</div>
      <div className={`type ${filter.type === '2' ? 'active' : ''}`} onClick={() => setFilter({ ...filter, type: "2" })}>参与审核</div>
    </div>
    <TitleBar title="惩罚列表"></TitleBar>
    <div className="list">
      {
        list.map(item => <div className={`item card ${item.status === '0' ? 'wait' : ''}`} key={item.id} onClick={() => openViewEditModal(item)}>
          <div className="title">{item.name}</div>
          <div className="data">{item.updatedAt.slice(0, 11)}</div>
        </div>)
      }
    </div>
    <Modal show={showAddModal} title="申请惩罚" tips={{ ok: '提审', cancel: '返回' }}>
      <div className="add-modal-info">
        <div className="target">
          <div className="section-title">选择被惩罚人</div>
          <div className="team-list">
            {team.users.map(user => <div className={`team-user ${user.id === punishInfo.userId ? 'active' : ''}`} key={user.id} onClick={() => userClick(user)}>
              <div className="avatar"><img src={user.avatar || require('../../assets/cute/icons8-google-photos-256.png')} alt="" /></div>
              <div className="name">{user.nickname}</div>
            </div>)}
          </div>
        </div>
        <div className="punish-head">
          <div className="section-title">输入惩罚原因</div>
          <LineInput label="原因" placeholder="输入惩罚原因" value={punishInfo.name} onChange={(e) => setPunishInfo({ ...punishInfo, name: e.target.value })} align="horizontal"></LineInput>
          <LineInput label="备注" placeholder="输入原因备注" value={punishInfo.remark} onChange={(e) => setPunishInfo({ ...punishInfo, remark: e.target.value })} align="horizontal"></LineInput>
        </div>
        <div className="punish-info">
          <div className="section-title">选择惩罚项
            <div className="tips">提示：绝对值取负,即10/-10均表示-10</div>
          </div>
          <div className="section-list">
            <LineInput label="品德" placeholder="输入品德惩罚" value={punishVal.morality} onChange={(e) => setPunishVal({ ...punishVal, morality: e.target.value })} type="number"></LineInput>
            <LineInput label="勇气" placeholder="输入勇气惩罚" value={punishVal.courage} onChange={(e) => setPunishVal({ ...punishVal, courage: e.target.value })} type="number"></LineInput>
            <LineInput label="坚毅" placeholder="输入坚毅惩罚" value={punishVal.perseverance} onChange={(e) => setPunishVal({ ...punishVal, perseverance: e.target.value })} type="number"></LineInput>
            <LineInput label="知识" placeholder="输入知识惩罚" value={punishVal.knowledge} onChange={(e) => setPunishVal({ ...punishVal, knowledge: e.target.value })} type="number"></LineInput>
            <LineInput label="智慧" placeholder="输入智慧惩罚" value={punishVal.wisdom} onChange={(e) => setPunishVal({ ...punishVal, wisdom: e.target.value })} type="number"></LineInput>
            <LineInput label="体魄" placeholder="输入体魄惩罚" value={punishVal.physique} onChange={(e) => setPunishVal({ ...punishVal, physique: e.target.value })} type="number"></LineInput>
            <LineInput label="沟通" placeholder="输入沟通惩罚" value={punishVal.communicate} onChange={(e) => setPunishVal({ ...punishVal, communicate: e.target.value })} type="number"></LineInput>
            <LineInput label="创造" placeholder="输入创造惩罚" value={punishVal.creativity} onChange={(e) => setPunishVal({ ...punishVal, creativity: e.target.value })} type="number"></LineInput>
            <LineInput label="执行" placeholder="输入执行惩罚" value={punishVal.implement} onChange={(e) => setPunishVal({ ...punishVal, implement: e.target.value })} type="number"></LineInput>
            <LineInput label="金币" placeholder="输入金币惩罚" value={punishVal.coin} onChange={(e) => setPunishVal({ ...punishVal, coin: e.target.value })} type="number"></LineInput>
          </div>
        </div>
      </div>
    </Modal>
    <Modal show={showDetailModal} title={filter.type === '1' ? "查看详情" : "审核惩罚"} tips={{ ok: filter.type === '1' ? "返回" : "审核", cancel: '返回' }}>
      <div className="edit-modal-info">
        <div className="line">
          <div className="label">被惩人</div>
          <div className="value user"><img src={editInfo.userInfo?.avatar || require('../../assets/cute/icons8-google-photos-256.png')} alt="" />{editInfo.userInfo?.nickname}</div>
        </div>
        <div className="line">
          <div className="label">原因</div>
          <div className="value">{editInfo.name}</div>
        </div>
        <div className="line">
          <div className="label">备注</div>
          <div className="value">{editInfo.remark}</div>
        </div>
        <div className="line">
          <div className="label user">创建者</div>
          <div className="value"><img src={editInfo.creatorInfo?.avatar || require('../../assets/cute/icons8-google-photos-256.png')} alt="" />{editInfo.creatorInfo?.nickname}</div>
        </div>
        <div className="line">
          <div className="label user">审核者</div>
          {editInfo.checkerInfo ? <div className="value"><img src={editInfo.checkerInfo?.avatar || require('../../assets/cute/icons8-google-photos-256.png')} alt="" />{editInfo.checkerInfo?.nickname}</div> : ""}
        </div>
        {filter.type === "1" ? <div className="line">
          <div className="label">状态</div>
          <div className="value">{editInfo.status === "1" ? <span className="done">已执行</span> : "审核中"}</div>
        </div> : ""}
        <div className="minus">
          {editInfo.morality ? <div className="item">
            <div className="label">品德:</div>{editInfo.morality}
          </div> : ''}
          {editInfo.courage ? <div className="item">
            <div className="label">勇气:</div>{editInfo.courage}
          </div> : ''}
          {editInfo.perseverance ? <div className="item">
            <div className="label">坚毅:</div>{editInfo.perseverance}
          </div> : ''}
          {editInfo.knowledge ? <div className="item">
            <div className="label">知识:</div>{editInfo.knowledge}
          </div> : ''}
          {editInfo.wisdom ? <div className="item">
            <div className="label">智慧:</div>{editInfo.wisdom}
          </div> : ''}
          {editInfo.physique ? <div className="item">
            <div className="label">体魄:</div>{editInfo.physique}
          </div> : ''}
          {editInfo.communicate ? <div className="item">
            <div className="label">沟通:</div>{editInfo.communicate}
          </div> : ''}
          {editInfo.creativity ? <div className="item">
            <div className="label">创造:</div>{editInfo.creativity}
          </div> : ''}
          {editInfo.implement ? <div className="item">
            <div className="label">执行:</div>{editInfo.implement}
          </div> : ''}
        </div>
        {filter.type === "2" ? <div className="status">
          <div className={`item agree ${checkType === '1' ? 'active' : ''}`} onClick={() => setCheckType("1")}>同意</div>
          <div className={`item disagree ${checkType === '2' ? 'active' : ''}`} onClick={() => setCheckType("2")}>不同意</div>
        </div> : ""}
      </div>
    </Modal>
  </div>
}