import style from "./Progress.module.scss"
import { useMemo } from "react"
export default function Progress({ children, ...props }) {

  const info = useMemo(() => {
    if (props.type !== 'circle') return {
      leftRotate: 0,
      rightRotate: 0,
      rightType: false,
    }
    let leftRotate = 0, rightRotate = 0, rightType = false
    if (props.percent > 50) {
      leftRotate = (props.percent - 50) * 3.6
      rightRotate = 0
      rightType = true
    } else {
      leftRotate = 0
      rightRotate = props.percent * 3.6
      rightType = false
    }
    return {
      leftRotate,
      rightRotate,
      rightType,
    }
  }, [props.percent, props.type]);

  function widthFn(width) {
    if (/^[0-9]*$/.test(width)) {
      return width + "px"
    } else if (/^[0-9]*%$/.test("100%")) {
      return width
    } else if (!isNaN(parseInt(width))) {
      return parseInt(width)
    }
  }
  const circleStyle = {
    width: widthFn(props.width),
    height: widthFn(props.width),
  }
  const ringMaskStyle = {
    width: `calc(${widthFn(props.width)} - ${props.lineWidth * 2}px)`,
    height: `calc(${widthFn(props.width)} - ${props.lineWidth * 2}px)`,
  }
  const maskLeftStyle = {
    transform: `rotate(${info.leftRotate}deg)`
  }
  const maskRightStyle = {
    transform: `rotate(${info.rightRotate}deg)`
  }
  const progressStyle = {
    '--bg': (props.color && props.color.bg) || 'var(--gray-200)',
    '--done': (props.color && props.color.done) || 'var(--primary)',
    '--mask': (props.color && props.color.mask) || 'var(--white)',
  }

  return <div className={style.progress} style={progressStyle}>
    {props.type === 'circle' ?
      <div className="circle" style={circleStyle}>
        <div className="ring"></div>
        <div className="mask-left" style={maskLeftStyle}></div>
        <div className={`mask-right ${info.rightType ? "clr" : ""}`} style={maskRightStyle}></div>
        <div className="ring-mask" style={ringMaskStyle}>
          {children || props.percent.toFixed(2) + "%"}
        </div>
      </div> :
      <div className="line">
        <div className="left">{children}</div>
        <div className="bar">
          <div className="done" style={{ width: `${props.percent}%` }}></div>
        </div>
        <div className="right">{props.value || props.percent}</div>
      </div>}
  </div>
}