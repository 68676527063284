import axios from "./request"
const check = {
  getList({ userId, type, pageNo = 1, pageSize = 50 }) {
    return axios.get("/punish/list", { params: { userId, type, pageNo, pageSize } })
  },
  add({ userId, teamId, creator, name, remark, morality, courage, perseverance, knowledge, wisdom, physique, communicate, creativity, implement, coin, }) {
    return axios.post("/punish/add", { userId, teamId, creator, name, remark, morality, courage, perseverance, knowledge, wisdom, physique, communicate, creativity, implement, coin, })
  },
  check({ id, status, userId }) {
    return axios.post("/punish/check", { id, status, userId })
  },
}
export default check