import Toast from "./Toast"
import ReactDOM from 'react-dom'
import React from 'react'

// 创建
function createNotification() {
  const div = document.createElement('div')
  document.body.appendChild(div)

  const notification = ReactDOM.render(<Toast key={"Toasts" + new Date().getTime()} />, div)
  return {
    addNotice(notice) {
      return notification.addNotice(notice)
    },
    destroy() {
      ReactDOM.unmountComponentAtNode(div)
      document.body.removeChild(div)
    }
  }
}

// 单例模式
let toasts;

function notice(type, content, duration, onClose) {
  toasts || (toasts = createNotification())
  return toasts.addNotice({ type, content, duration, onClose })
}

function info(content, duration = 3000, onClose) {
  return notice("info", content, duration, onClose)
}
function success(content = "成功", duration = 3000, onClose) {
  return notice("success", content, duration, onClose)
}
function warn(content, duration = 3000, onClose) {
  return notice("warning", content, duration, onClose)
}
function error(content, duration = 3000, onClose) {
  return notice("error", content, duration, onClose)
}
function loading(content = "加载中...", duration = 3000, onClose) {
  return notice("loading", content, duration, onClose)
}

export default {
  info,
  success,
  warn,
  error,
  loading
}