import axios from "./request"

const category ={
  getAll(teamId){
    return axios.get("/category/all",{params:{teamId}})
  },
  getTree(id, teamId){
    return axios.get("/category/tree", { params: {id, teamId}})
  },
  addCategory({pid, teamId, name, remark, sort }){
    return axios.post("/category/add", {pid, teamId, name, remark, sort })
  },
  editCategory({id, teamId, pid, name, remark, sort }){
    return axios.post("/category/edit", {id, teamId, pid, name, remark, sort })
  },
  delCategory(id, teamId){
    return axios.delete("/category/delete", {params: {id, teamId}})
  },
}
export default category