const colors = [
  "#80d2b8",
  "#e9a986",
  "#50c14b",
  "#56b5ef",
  "#9f8066",
  "#ee3e54",
  "#fbcd59",
  "#50c14b",
  "#8889b9",
]
export default colors