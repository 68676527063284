import axios from "./request"

const team = {
  getNum(teamId) {
    return axios.get(`/team/num/`, {
      params: {
        id: teamId
      }
    })
  },
  create({ userId, name, remark }) {
    return axios.post("/team/create", { userId, name, remark })
  },
  join(userId, teamId) {
    return axios.get(`/team/join/`, {
      params: {
        userId, teamId
      }
    })
  },
  check(id, status) {
    return axios.get(`/team/check/`, {
      params: {
        id, status
      }
    })
  },
  change(userId, teamId) {
    return axios.get(`/team/change/`, {
      params: {
        userId, teamId
      }
    })
  },
  getInfo(id) {
    return axios.get(`/team/${id}`)
  },
  changeInfo({id, name, remark}){
    return axios.post(`/team/changeInfo`,{id, name, remark})
  },
  changeMemberBirthday({teamId, userId, birthday}){
    return axios.post(`/team/changeMemberBirthday`,{teamId, userId, birthday})
  }
}

export default team