import "./Achievement.scss"
import { useState, useEffect, useContext } from "react"
import Modal from "../../components/Modal/Modal"
import Toast from "../../components/Toast";
import AchievementApi from "../../api/achievement"

import Context from "../../context"

export default function Achievement() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)

  const [user, setUser] = useState({})
  const [list, setList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [achieve, setAchieve] = useState({});
  const [stock, setStock] = useState([]);
  const [bread, setBread] = useState([]);
  const [level, setLevel] = useState(0);


  // 获取成就分类
  function getAchieveList() {
    AchievementApi.getTreeMarkUser("1", user.id || JSON.parse(localStorage.getItem('user')).id, user.teamId || JSON.parse(localStorage.getItem('user')).teamId ).then(res => {
      res.data.forEach(v => v.show = false)
      setList([...res.data])
    })
  }
  // 展开或关闭成就分类
  function toggleCollapse(item) {
    setList(list.map(v => {
      if (v.id === item.id) {
        v.show = !item.show
        setStock([v])
        setLevel(0)
        setBread(['*'])
      } else {
        v.show = false
      }
      return v
    }))
  }
  // 点击子分类
  function childClick(child) {
    setStock([...stock, child])
    setLevel(level + 1)
    setBread([...bread, child.name])
    addCallback(()=>{
      setStock(stock.slice(0, level + 1))
      setLevel(level)
      setBread(bread.slice(0, level + 1))
      removeCallback()
    },null)
  }
  // 点击面包屑
  function breadClick(idx) {
    for(let i=level;i>idx;i--){
      removeCallback()
    }
    setStock(stock.slice(0, idx + 1))
    setLevel(idx)
    setBread(bread.slice(0, idx + 1))
  }
  // 点击成就
  function achieveClick(achieve) {
    setAchieve(achieve)
    setShowModal(true)
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')))
    getAchieveList()
  }, [])

  useEffect(() => {
    if (showModal) {
      // 添加tabbar回调
      addCallback(() => { setShowModal(false); removeCallback(); }, () => {
        function close() {
          setShowModal(false);
          removeCallback();
        }
        if (achieve.users.length) {
          close()
        } else {
          // 提交数据
          AchievementApi.addCheck({
            teamId: user.teamId,
            userId: user.id,
            achievementId: achieve.id
          }).then(res => {
            Toast.success("提审成功，请等待审核完成", 3000)
            close()
          })

        }
      })
    }
  }, [showModal])

  return <div className="page achievement">
    <div className="achieve-category-list">
      {list.map(item => (
        <div key={item.id} className={`item ${item.show ? 'active' : ''}`}>
          <div className="title" onClick={() => toggleCollapse(item)}>
            <div className="icon">
              <img src={require(`../../assets${item.icon}`)} />
            </div>
            <div className="text">{item.name}</div>
          </div>
          {
            item.show && <div className="content">
              <div className="breads">
                {
                  bread.map((b, idx) => <div key={idx} onClick={() => { breadClick(idx) }} className="bread">/ {b}</div>)
                }
              </div>
              <div className="children">
                {
                  stock[level].children.map(child => <div className="child" onClick={() => childClick(child)} key={child.id}>
                    {child.name}
                  </div>)
                }
              </div>
              <div className="achievement">
                {
                  stock[level].achievements.map(achieve => <div key={achieve.id} className={`achieve-item ${achieve.users.length ? 'done' : ''}`} onClick={() => achieveClick(achieve)}>
                    <div className="achieve-info">
                      <div className="achieve-icon">
                        <img src={require("../../assets/cute/icons8-prize-100.png")} alt="" />
                      </div>
                      <div className="achieve-name">
                        {achieve.name}
                      </div>
                    </div>
                    <div className="achieve-state">
                      {achieve.users.length ?
                        <img src={require('../../assets/cute/icons8-check-circle-128.png')} /> :
                        <img src={require('../../assets/cute/icons8-check-circle.gif')} />}
                    </div>
                  </div>)
                }
              </div>
            </div>
          }
        </div>
      ))
      }
    </div>
    <Modal show={showModal} title="成就详情" tips={{ ok: achieve.users && achieve.users.length ? '关闭' : '提审', cancel: '返回' }}>
      <div className="modal-info">
        <div className="title" style={{background: achieve.color}}>
          {achieve.name}
        </div>
        <div className="remark">
          {achieve.remark || "暂无备注"}
        </div>
        <div className="info" >
          <div className="color-coin">
            <div className="item">
              <div className="label">品质:</div>
              <div className="color" style={{ background: achieve.color }}></div>
            </div>
            {achieve.coin ? <div className="item"><div className="label">金币:</div>{achieve.coin}</div> : ''}
          </div>

          <div className="rise">
            {achieve.morality ? <div className="item">
              <div className="label">品德:</div>{achieve.morality}
            </div> : ''}
            {achieve.courage ? <div className="item">
              <div className="label">勇气:</div>{achieve.courage}
            </div> : ''}
            {achieve.perseverance ? <div className="item">
              <div className="label">坚毅:</div>{achieve.perseverance}
            </div> : ''}
            {achieve.knowledge ? <div className="item">
              <div className="label">知识:</div>{achieve.knowledge}
            </div> : ''}
            {achieve.wisdom ? <div className="item">
              <div className="label">智慧:</div>{achieve.wisdom}
            </div> : ''}
            {achieve.physique ? <div className="item">
              <div className="label">体魄:</div>{achieve.physique}
            </div> : ''}
            {achieve.communicate ? <div className="item">
              <div className="label">沟通:</div>{achieve.communicate}
            </div> : ''}
            {achieve.creativity ? <div className="item">
              <div className="label">创造:</div>{achieve.creativity}
            </div> : ''}
            {achieve.implement ? <div className="item">
              <div className="label">执行:</div>{achieve.implement}
            </div> : ''}
          </div>
        </div>
        {
          achieve.users && achieve.users.length ? <div className="done">
            <img src={require('../../assets/cute/icons8-check-circle.gif')} />
          </div> : ''
        }
      </div>

    </Modal>
  </div>
}