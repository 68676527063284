import "./Signin.scss"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import UserApi from "../../api/user"
import Toast from "../../components/Toast"

import LineInput from "../../components/LineInput/LineInput"

export default function Signin() {
  const [email, setEmail] = useState("")
  const [psw, setPwd] = useState("")
  const [enter, setEnter] = useState("")

  let navigate = useNavigate()

  function emailChange(e) {
    setEmail(e.target.value)
  }
  function pswChange(e) {
    setPwd(e.target.value)
  }
  async function submit() {
    let emailRegExp = /^[A-Za-z0-9\u4e00-\u9fa5\#\$_\&\-\!\+\=\*]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/
    if (!emailRegExp.test(email)) {
      Toast.error("邮箱格式不对")
      return
    }
    let data = await UserApi.signin(email, psw)
    localStorage.setItem("user", JSON.stringify(data.data))
    if (data.token) {
      localStorage.setItem("tk", data.token)
      navigate("/", { replace: true })
    }
  }

  useEffect(() => {
    if (email && psw && enter) {
      submit()
    }
  }, [enter, email, psw])

  return <div className="page signin">
    <div className="card form">
      <div className="logo logo-center logo-small">
        <img src={require('../../assets/cute/icons8-google-photos-256.png')} alt="" />
      </div>
      <LineInput label="邮箱" value={email} onChange={emailChange}></LineInput>
      <LineInput label="密码" value={psw} type="password" onChange={pswChange} onSubmit={() => setEnter(true)}></LineInput>
      <div className="btns">
        <div className="forget-btn" onClick={() => navigate("/forget")}>忘记密码</div>
        <div className="signin-btn" onClick={submit}>登录</div>
        <div className="signup-btn" onClick={() => navigate("/signup")}>注册</div>
      </div>
    </div>
  </div>
}