import { useEffect, useState, useContext } from "react"
import "./Wallet.scss"
import TitleBar from "../../components/TitleBar/TitleBar"
import Modal from "../../components/Modal/Modal"

import {walletCategory,walletIcon} from "../../config/wallet"

import WalletApi from "../../api/wallet"

import Context from "../../context"

export default function Wallet(){
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)

  const [user, setUser] = useState({})
  const [amount, setAmount] = useState("")
  const [list, setList] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [itemInfo, setItemInfo] = useState({})

  function getBillList(){
    WalletApi.getBill({userId:user.id || JSON.parse(localStorage.getItem('user')).id}).then(res=>{
      setList(res.data)
    })
  }
  function getCurrentCoin(){
    WalletApi.getUserCoin(user.id || JSON.parse(localStorage.getItem('user')).id).then(res=>{
      setAmount(res.data)
    })
  }

  useEffect(()=>{
    setUser(JSON.parse(localStorage.getItem('user')))
    getBillList()
    getCurrentCoin()
  },[])

  function itemClick(item) {
    setShowModal(true)
    setItemInfo(item)
  }
  useEffect(()=>{
    if(showModal){
      addCallback(()=>{
        setShowModal(false)
        removeCallback()
      },()=>{
        setShowModal(false)
        removeCallback()
      })
    }
  },[showModal])

  return <div className="page wallet">
    <div className="overage card">
      <div className="label">可用余额 (金币)</div>
      <div className="amount">§{Number(amount).toFixed(2)}</div>
    </div>
    <TitleBar title="钱包"></TitleBar>
    <div className="list">
      {
        list.map(item=><div className="item card" key={item.id} onClick={()=>itemClick(item)}>
          <div className="icon"><img src={item.category ? require(`../../assets${walletIcon[item.category]}`):''}/></div>
          <div className="info">
            <div className="line trade">
              <div className="category">{walletCategory[item.category]}</div>
              <div className="amount">{item.type==='1'?'- §' + Number(item.amount).toFixed(2):'+ §' + Number(item.amount).toFixed(2)}</div>
            </div>
            <div className="line settlement">
              <div className="date">{item.createdAt}</div>
              <div className="current">余额： §{Number(item.currentTotal).toFixed(2)}</div>
            </div>
          </div>
        </div>)
      }
    </div>
    <Modal show={showModal} title="账单详情" tips={{ ok: '返回', cancel: '返回' }}>
      <div className="bill-info">
        <div className="total">
          <div className="cate">
            <img src={itemInfo.category ?require(`../../assets${walletIcon[itemInfo.category]}`):''}/>
            {walletCategory[itemInfo.category]}</div>
          <div className="amount">{itemInfo.type==='1'?'- ':'+ '}§{Number(itemInfo.amount).toFixed(2)}</div>
          <div className="yue">余额：§{Number(itemInfo.currentTotal).toFixed(2)}</div>
        </div>
        <div className="line">
          <div className="label">交易ID</div><div className="content">{itemInfo.id}</div>
        </div>
        <div className="line">
          <div className="label">交易类型</div><div className="content">{walletCategory[itemInfo.category]}</div>
        </div>
        {
          itemInfo.achievement && <div className="line">
            <div className="label">达成成就</div><div className="content">{itemInfo.achievement.name}</div>
          </div>
        }
        <div className="line">
          <div className="label">交易前总额</div><div className="content">§{Number(itemInfo.preTotal).toFixed(2)}</div>
        </div>
        <div className="line">
          <div className="label">交易手续费</div><div className="content">§{Number(itemInfo.charge).toFixed(2)}</div>
        </div>
        <div className="line">
          <div className="label">交易备注</div><div className="content">{itemInfo.remark}</div>
        </div>
        <div className="line">
          <div className="label">交易时间</div><div className="content">{itemInfo.createdAt}</div>
        </div>
      </div>
    </Modal>
  </div>
}