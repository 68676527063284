import axios from 'axios'
import {baseURL} from "./config"
import Toast from '../components/Toast'

// 创建axios实例
const instance = axios.create({
  timeout: 1000 * 10,
  baseURL
})
// http request 拦截器
instance.interceptors.request.use(
  config => {
    config.headers['Authorization'] = "Bearer "+ localStorage.getItem('tk') 
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

function toLogin(){
  localStorage.removeItem('user');
  localStorage.removeItem('tk');
  window.location.href = '/#/signin';
  Toast.error("未登录或登录过期")
}

// http response 拦截器
instance.interceptors.response.use(
  response => {
    // 拦截响应，做统一处理
    const res = response.data
    if (response.config.url === "/other/proxy/bingWallpaper") {
      return res
    }
    if(response.headers.Authorization){
      localStorage.setItem('tk', response.headers.Authorization)
    }
    if (res.code === "1") {
      return res
    } else {
      switch (res.code) {
        case "0":
          Toast.error(res.msg)
          break;
        case 401:
          toLogin();
          break;
        default:
      }
      return Promise.reject(res)
    }
  },
  // 接口错误状态处理，也就是说无响应时的处理
  error => {
    if (!error.response) {
      console.log(error.message)
      if (error.message.includes('timeout')) {
      }
      return Promise.reject(error.message); // 返回接口返回的错误信息
    }
    console.log(error);
    if(error.code == "ERR_NETWORK"){
      Toast.error("服务器连接失败")
    }
    const status = error.response.status
    if (status)
      switch (status) {
        case 401:
        case 402:
          toLogin()
          break;
        case 404:
          Toast.error("未知资源");
          break;
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
          Toast.error("服务错误")
          break;
        default:
      }
    return Promise.reject(error.response.statusText) // 返回接口返回的错误信息
  }
)

export default instance