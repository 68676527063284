import "./Signup.scss"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Toast from "../../components/Toast/index"
import SendEmail from "../../components/SendEmail/SendEmail"
import LineInput from "../../components/LineInput/LineInput"
import UserApi from "../../api/user"
import { debounce } from "../../utils/tools.js"


const emailRegExp = /^[A-Za-z0-9\u4e00-\u9fa5\#\$_\&\-\!\+\=\*]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/

export default function Signup() {
  const [enter, setEnter] = useState("")
  const [info, setInfo] = useState({
    nickname: '',
    email: '',
    code: '',
    psw: '',
    repsw: '',
  })

  let navigate = useNavigate()

  function infoChange(e, key) {
    let info_c = { ...info }
    info_c[key] = e.target.value
    setInfo(info_c)
  }

  async function signupSubmit() {
    if (!info.nickname || !info.code || !info.email || !info.psw || !info.repsw) {
      Toast.error("信息录入不完整")
      return
    }
    if (!emailRegExp.test(info.email)) {
      Toast.error("邮箱格式不对")
      return
    }
    if (info.psw !== info.repsw) {
      Toast.error("两次输入密码不一致，请重新输入")
      return
    }
    const data = await UserApi.signup(info.nickname, info.code, info.email, info.psw)
    if (data) {
      Toast.success("注册成功，即将跳转登录", 2000, function () {
        navigate("/signin", { replace: true })
      })
    }
  }
  // 防抖
  const submitDebounced = debounce(() => {
    signupSubmit()
  }, 600)

  useEffect(() => {
    if (info.nickname && enter) {
      submitDebounced()
    }
  }, [enter, info.nickname])

  return <div className="page signup">
    <div className="card form">
      <div className="logo logo-center logo-small">
        <img src={require('../../assets/cute/icons8-google-photos-256.png')} alt="" />
      </div>
      <LineInput label="昵称" value={info.nickname} onChange={(e) => infoChange(e, 'nickname')}></LineInput>
      <div className="flex">
        <LineInput label="邮箱" value={info.email} onChange={(e) => infoChange(e, 'email')}></LineInput>
        <SendEmail email={info.email} type="register"></SendEmail>
      </div>
      <LineInput label="验证码" value={info.code} onChange={(e) => infoChange(e, 'code')}></LineInput>
      <LineInput label="密码" value={info.psw} type="password" onChange={(e) => infoChange(e, 'psw')}></LineInput>
      <LineInput label="重复密码" value={info.repsw} type="password" onChange={(e) => infoChange(e, 'repsw')} onSubmit={() => setEnter(true)}></LineInput>
      <div className="btns">
        <div className="forget-btn"></div>
        <div className="signup-btn" onClick={submitDebounced}>注册</div>
        <div className="signin-btn" onClick={() => navigate("/signin")}>登录</div>
      </div>
    </div>
  </div>
}