import axios from "./request"
const wallet ={
  getBill({userId}){
    return axios.get("/wallet/bill", {params:{userId}})
  },
  getCategory(nickname, account, psw){
    return axios.get(`/wallet/category/${'1'}`)
  },
  getUserCoin(userId){
    return axios.get(`/wallet/${userId}`)
  },
}
export default wallet