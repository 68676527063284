import "./Loan.scss"
import Progress from "../../../components/Progress/Progress"
import Modal from "../../../components/Modal/Modal"
import TitleBar from "../../../components/TitleBar/TitleBar"
import LineInput from "../../../components/LineInput/LineInput"
import Toast from "../../../components/Toast"
import { useEffect, useState, useContext } from "react"
import BankApi from "../../../api/bank"
import bankUtils from "../../../utils/bank"

import Context from "../../../context"

export default function Loan() {
  // tabbar callback
  const { removeCallback, addCallback } = useContext(Context)

  const [user, setUser] = useState({})
  const [quota, setQuota] = useState({})
  const [rate, setRate] = useState(6.5)
  const [loanRecords, setLoanRecords] = useState([])
  const [sortDesc, setSortDesc] = useState(true)
  const [addInfo, setAddInfo] = useState({ amount: -1, period: 1, remark: "" })
  const [loanInfo, setLoanInfo] = useState({})
  const [showModal, setShowModal] = useState(false)
  const [showAddModal, setShowAddModal] = useState(false)
  const [showRepayModal, setShowRepayModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [repayInfo, setRepayInfo] = useState({amount: -1, period: 1, remark: ""})

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")))
    //请求数据
    getQuota()
    getLoanRecords()
  }, [])

  function getQuota() {
    BankApi.getLoanAmount(user.id || JSON.parse(localStorage.getItem("user")).id).then(res => {
      setQuota({
        total: res.amountTotal,
        current: res.amountTotal - res.amountUsed
      })
      setRate(res.rate)
    })
  }
  function getLoanRecords() {
    BankApi.getLoanRecords(user.id || JSON.parse(localStorage.getItem("user")).id).then(res => {
      setLoanRecords(res.data || [])
    })
  }

  function changeSort() {
    setSortDesc(!sortDesc)
    getLoanRecords()
  }

  function itemClick(item) {
    setLoanInfo(item);
    setShowModal(true);
    addCallback(() => {
      setShowModal(false);
      removeCallback("back");
    }, () => {
      // 提交数据
      setShowModal(false);
      removeCallback("ok");
    })
  }

  function showAdd() {
    setShowAddModal(true);
    setAddInfo({ amount: 0, period: 1, remark: "" })
  }
  useEffect(() => {
    if (addInfo.amount !== -1) {
      removeCallback()
    }
    if(showAddModal){
      addCallback(() => { setShowAddModal(false); removeCallback(); }, () => {
        // 校验数据
        if (addInfo.amount > quota.current || addInfo.amount<1) {
          Toast.error("超过可用额度范围")
          return
        }
        // 提交数据
        if (addInfo.period < 1) {
          Toast.error("最少1期")
          return
        }
        BankApi.createLoan({
          userId: user.id,
          teamId: user.teamId,
          ...addInfo,
        }).then(res => {
          setShowAddModal(false);
          removeCallback();
          getLoanRecords()
        })
      })
    }
  }, [showAddModal, addInfo])

  function showProtocol() {
    setShowInfoModal(true);
    addCallback(() => {
      setShowInfoModal(false);
      removeCallback("back");
    }, () => {
      // 提交数据
      setShowInfoModal(false);
      removeCallback("ok");
    })
  }

  function statusJSX(item) {
    switch (item.status) {
      case '0':
        return <div className="status">待审核</div>;
      case '2':
        return <div className="status nopass">未通过</div>;
      case '3':
        return <div className="status done">已还完</div>;
      default: 
        return <div>
          <div className="status"><span>{item.periodDown}</span><span> / {item.period}</span><span className="repay" onClick={(e)=> showReply(e,item) }>还款</span></div>
        </div>;
    }
  }
  function statusName(status){
    let statusObj = {0: '待审核', 1: '偿还中', 2: '未通过', 3: '已完结', 4: '异常/逾期'}
    return statusObj[status]
  }

  function showReply(e,item){
    e.stopPropagation()
    setShowRepayModal(true);
    setRepayInfo({ ...item })
  }

  function dateCompare(date, periodDown){
    let base = new Date(date), y = base.getFullYear(),m=base.getMonth()+1,d=base.getDate()
    let year = y, month = m+periodDown+1, day = d
    if(month > 12){
      year ++
      month = month - 12
    }
    let timestamp =  new Date(`${year}-${month}-${day} 23:59:59`) - new Date()
    return {
      canRepay: timestamp <= 3 * 24 * 60 * 60 * 1000,
      currentDeadline: `${year}-${month}-${day}`,
      isOverdue: timestamp < 0
    }
  }
  useEffect(() => {
    if(repayInfo.amount !== -1){
      removeCallback();
    }
    if(showRepayModal){
      addCallback(() => { setShowRepayModal(false); removeCallback(); }, () => {
        if(repayInfo.status!=='1'){
          window.location.reload()
          return
        }
        let compare = dateCompare(repayInfo.date, repayInfo.periodDown)
        if(!compare.canRepay){
          Toast.warn(`未到还款期间，请在本期还款日${compare.currentDeadline }前三天内还款`)
          return
        }
        BankApi.repayLoan({
          id: repayInfo.id,
          userId: repayInfo.userId,
        }).then(res => {
          setShowRepayModal(false);
          removeCallback();
          getLoanRecords()
        })
      })
    }
  }, [showRepayModal, repayInfo])

  return <div className="page loan">
    <div className="card">
      <div className="total-quota">
        <div className="bigInfo">
          <div className="canuse">§{bankUtils.moneyFormat(quota.current)}</div>
          <div className="label">可用额度</div>
          <div className="total">§{bankUtils.moneyFormat(quota.total)}</div>
          <div className="label">总额度</div>
          <div className="rate">{rate}%</div>
          <div className="label">今日利率</div>
        </div>
        <Progress type="circle" percent={quota.current / quota.total * 100} width="120">
          <div className="circle-progress-content">
            <div>剩余可用</div>
            <div>{(quota.current / quota.total * 100).toFixed(2)}%</div>
          </div>
        </Progress>
      </div>
      <div className="detail-btn">
        <span className="loan" onClick={showAdd}>借款</span>
        <span className="protocol" onClick={showProtocol}>查看规则</span>
      </div>
    </div>
    <TitleBar title="借款记录" sort={sortDesc} changeSort={changeSort}></TitleBar>
    <div className="loan-list">
      {
        loanRecords.map(item => <div className="item" key={item.id} onClick={() => itemClick(item)}>
          <div className="title">
            <div className="name">§{bankUtils.moneyFormat(item.repay)}</div>
            { dateCompare(item.date,item.periodDown).isOverdue && item.status === '1' ? <div className="overdue">已逾期</div> : "" }
            { statusJSX(item) }
          </div>
          <div className="info">
            <div className="block">
              <div className="label">借款额度</div>
              <div className="value">§{bankUtils.moneyFormat(item.amount)}</div>
            </div>
            <div className="block">
              <div className="label">利息</div>
              <div className="value">§{bankUtils.moneyFormat(item.interest)}</div>
            </div>
            <div className="block">
              <div className="label">利率</div>
              <div className="value">{item.rate}%</div>
            </div>
          </div>
        </div>)
      }
    </div>
    <Modal show={showModal} title="借款详情" tips={{ cancel: '返回', ok: loanInfo.status==='1'?'还款':'返回' }}>
      <div className="loan-detials">
        { dateCompare(repayInfo.date, repayInfo.periodDown).isOverdue && repayInfo.status === '1' ? <div className="overdue">已逾期, 请尽快偿还</div> : "" }
        <div className="line">
          <div className="label">状态</div>
          <div className="value">{statusName(loanInfo.status)}</div>
        </div>
        <div className="line">
          <div className="label">应还总额</div>
          <div className="value">§{bankUtils.moneyFormat(loanInfo.repay)}</div>
        </div>
        <div className="line">
          <div className="label">每期应还金额</div>
          <div className="value single">§{bankUtils.moneyFormat(loanInfo.singleAmount)}</div>
        </div>
        <div className="line">
          <div className="label">已还总额</div>
          <div className="value">§{bankUtils.moneyFormat(loanInfo.repaid)}</div>
        </div>
        <div className="line">
          <div className="label">总期数</div>
          <div className="value">{loanInfo.period} 期</div>
        </div>
        <div className="line">
          <div className="label">已还期数</div>
          <div className="value">{loanInfo.periodDown} 期</div>
        </div>
        <div className="line">
          <div className="label">借款金额</div>
          <div className="value">§{bankUtils.moneyFormat(loanInfo.amount)}</div>
        </div>
        <div className="line">
          <div className="label">利息</div>
          <div className="value">§{bankUtils.moneyFormat(loanInfo.interest)}</div>
        </div>
        <div className="line">
          <div className="label">利率</div>
          <div className="value">{loanInfo.rate}%</div>
        </div>
        <div className="line">
          <div className="label">手续费</div>
          <div className="value">§{bankUtils.moneyFormat(loanInfo.charge)}</div>
        </div>
        <div className="line">
          <div className="label">借款备注</div>
          <div className="value">{loanInfo.remark}</div>
        </div>
      </div>
    </Modal>
    <Modal show={showAddModal} title="借款" tips={{ cancel: '返回', ok: '提审' }}>
      <div className="create-loan">
        <LineInput align="horizontal" label="金额 §" value={addInfo.amount} type='number' placeholder="请输入借贷金额" onChange={(e) => setAddInfo({ ...addInfo, amount: e.target.value })}></LineInput>
        <LineInput align="horizontal" label="期数" value={addInfo.period} type='number' placeholder="请输入还款期数" onChange={(e) => setAddInfo({ ...addInfo, period: e.target.value })}></LineInput>
        <LineInput align="horizontal" label="备注" value={addInfo.remark} placeholder="请输入存款备注" onChange={(e) => setAddInfo({ ...addInfo, remark: e.target.value })}></LineInput>
      </div>
    </Modal>
    <Modal show={showRepayModal} title="还款" tips={{ cancel: '返回', ok: '还款' }}>
      <div className="repay-loan">
        { dateCompare(repayInfo.date, repayInfo.periodDown).isOverdue && repayInfo.status === '1' ? <div className="overdue">已逾期, 请尽快偿还</div> : "" }
        <div className="line">
          <div className="label">本期应还</div>
          <div className="value point">§{bankUtils.moneyFormat(repayInfo.singleAmount)}</div>
        </div>
        <div className="line" style={{marginBottom: '24px'}}>
          <div className="label">已还期数</div>
          <div className="value"><span className="current-period">{ repayInfo.periodDown }</span><span> / { repayInfo.period }</span></div>
        </div>
        <div className="line">
          <div className="label">借款总额</div>
          <div className="value">§{bankUtils.moneyFormat(repayInfo.repay)}</div>
        </div>
        <div className="line">
          <div className="label">已还总额</div>
          <div className="value">§{bankUtils.moneyFormat(repayInfo.repaid)}</div>
        </div>
        <div className="line">
          <div className="label">利息</div>
          <div className="value">§{bankUtils.moneyFormat(repayInfo.interest)}</div>
        </div>
        <div className="line">
          <div className="label">利率</div>
          <div className="value">{repayInfo.rate}%</div>
        </div>
      </div>
    </Modal>
    <Modal show={showInfoModal} title="借款协议及相关说明" tips={{ cancel: '返回', ok: '确认' }}>
      <div className="protocol">
        <h1>借贷说明</h1>
        <h2>一、定义</h2>
        <p>银行：本系统金币池银行，以下简称银行</p>
        <p>用户：发起借还款方，以下简称您</p>
        <h2>二、协议确认与补充、修改</h2>
        <p>本协议包括协议正文及银行已发布的或将来可能发布的各类规则（含业务规范、通知、公告等）。所有上述各类规则等为本协议不可分割的组成部分，与本协议正文具有同等法律效力。如上述各类规则等与本协议有冲突，则以后发布者为准。您可以在当前页面中查阅相关协议条款。银行根据业务发展对本协议内容进行变更的，将通过本页面发布，不再逐一通知每位用户。如果您不接受该变更，有权不适用此服务；如果在上述变更发布后，您继续使用服务，视为您接受该变更。</p>
        <h2>三、服务及交易规则</h2>
        <p>3.1 使用本服务则表示您接受后续规则。</p>
        <p>3.2 借款需要引导者审核，审核通过后，款项会直接转账至申请者活期账户。</p>
        <p>3.3 可借款总额根据用户当前定期及活期存款按一定比例动态计算, 最多可借贷用户银行定活期总存款的1.95倍。</p>
        <p>3.4 借款利率每日更新。利率在3%-7%之间变动</p>
        <p>3.5 借款按期数还款，最低1期，一期为一个月，除29、30、31日借款以28日为还款日期外，其余日期以借款当日为下次还款期限，可提前还款</p>
        <p>3.6 借款还款日期之后未还款的，超出日期加倍征收利息。</p>
        <p>3.7 借款收取固定手续费 §{bankUtils.moneyFormat('100')}，在放款金额中直接扣除，还款不收取手续费。</p>
        <p>3.8 交易因数据原因出现问题的，需联系系统管理员找回数据，如数据无法找回的，按历史数据推断补偿，补偿方式最终解释权归银行系统所有。</p>
        <p>3.9 其余未尽事项，以银行解释为准。</p>
        <h2>四、其他</h2>
        <p>4.1 银行已提请您特别注意有关免除、限制银行责任，和银行单方面拥有某些权利以及增加您义务或免除、限制您权利的条款，并已应您的要求对上述条款予以充分解释说明，各方对本协议条款的理解完全一致，并自愿接受约束。</p>
        <p>4.2 若您与银行之间发生争议，由双方协商解决。协商不成的，就算了，毕竟银行钱包都是我开的。</p>
        <p>4.3 本协议的成立、生效、履行、解释及纠纷解决，不适用于法律，仅适用金融惯例。</p>
        <p>4.4 本协议条款无论因何种原因部分无效或不可执行，都不影响本协议其他条款的效力。</p>
        <p>4.5 本协议中未尽事宜，按照金融惯例办理。</p>
        <p>4.6 本协议中出现的金融惯例一词的解释权归银行所有。</p>
      </div>
    </Modal>
  </div>
}